<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </SearchList>
    <div class="m-t1 tableBox">
      <div class="flex  ju-between m-b1">
        <div class="sys-screenTitle">共&nbsp;<a>{{ data.length }}</a>&nbsp;条数据</div>
        <div class="flex al-center sys-screenTitle m-r3">
          <div v-for="item in titleList" :key="item.id" class="m-r2">
             {{ item.title }}&nbsp;<a>{{item.frontUnit}}{{ item.value }}{{ item.unit }}</a>
          </div>
        </div>
      </div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #rate="{ record }">
         <span v-if="record.rate">{{ record.rate }}%</span>
        </template>
      </a-table>
    </div>

  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import moment from 'moment'
import { transitionTimeYM, getTableBody, IfNull } from '@/utils/util'
import { conversionChannel } from '@/api/businessCenter/businessCenter'
import { useStore } from 'vuex'
import { cloneDeep } from 'lodash-es'

const store = useStore()
const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const searchMsg = ref({})



const searchAdd = (e) => {
  let msg = cloneDeep(e)
 if(msg.channelSubId){
  msg.channelSubId = msg.channelSubId[1]
 }
  msg.month = transitionTimeYM(msg.month)
  searchMsg.value = msg
  getProfitList()
}
const resetBtn = () => {
  searchMsg.value = {
    month: transitionTimeYM(new Date().toLocaleDateString()),
  }
  getProfitList()
}

const getProfitList = () => {
  loading.value = true
  conversionChannel({
    ...searchMsg.value,
  }).then(res => {
    if (res.code !== 10000) return
    if(!res.data) return
    data.value = res.data
    let list = res.data.slice(-1)
    if(list.length > 0){
      titleList.value.forEach( item => {
      item.value = list[0][item.key]
    })
    }
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}

const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  searchMsg.value = {
    month: transitionTimeYM(new Date().toLocaleDateString()),
  }
  getProfitList()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const titleList = ref([
  {
    title:'领取数量',
    key:'clueTotal',
    value:0
  },
  {
    title:'总成交',
    key:'clueDeal',
    value:0
  },
  {
    title:'转化率',
    key:'rate',
    value:0,
    unit:'%'
  },
  {
    title:'无效数量',
    key:'temporaryNum',
    value:0
  },
  {
    title:'总交车毛利',
    key:'grossProfit',
    value:0,
    frontUnit:'￥'
  },
])
const iptData = ref([
  {
    type: "selectTimeYM",
    placeholder: "年月",
    value: moment(new Date()),
    prop: "month",
    width: 200,
    allowClear:false
  },
  {
    type: "cascader",
    placeholder: "渠道来源",
    value:null,
    prop: "channelSubId",
    width: 200,
    opt: store.state.enumAll.ChannelTypeEnum
  },
  {
    type: "input",
    placeholder: "业务员",
    value:null,
    prop: "saleName",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '业务员',
    dataIndex: 'saleName',
    align: 'center',
  },
  {
    title: '月份',
    dataIndex: 'month',
    align: 'center',
  },
  {
    title: '渠道名称',
    dataIndex: 'channelSubName',
    align: 'center',
  },
  {
    title: '领取数量',
    dataIndex: 'clueTotal',
    align: 'center',
  },
  {
    title: '总成交',
    dataIndex: 'clueDeal',
    align: 'center',
  },
  {
    title: '转化率',
    dataIndex: 'rate',
    slots: {
      customRender: 'rate'
    },
    align: 'center',
  },
  {
    title: '无效数量',
    dataIndex: 'temporaryNum',
    align: 'center',
  },
  {
    title: '总交车毛利（元）',
    dataIndex: 'grossProfit',
    align: 'center',
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>