<template>
  <div ref="modal" class="classmodal">
    <div class="titleBox flex al-center ju-between">
      <div style="width: 95%" class="flex al-center">
        <div v-for="item in headList" :key="item.id" >
          <div v-if="item.value" class="flex al-center m-r4">
            <span class="fz-cu">{{ item.title }}：</span>
            <span v-if="item.key" class="headVal">
              {{ item.value }}
            </span>
            <span v-if="!item.key">
              <span v-for="opt in item.children" class="head-text " :key="opt.id">
                 <span v-if="opt.value" class="m-r1">
                  <span>{{ opt.title }}&nbsp;&nbsp;</span>
                  <span class="headVal">{{ opt.value }}</span>
                 </span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="flex" style="width: 3%">
        <img @click="openColor" class="m-l2 cur-p" style="width:18px;height:18px" src="@/assets/images/newTransport/colorChange.png" alt="">
      </div>
    </div>

    <div v-if="loading" class="flex al-center ju-center" style="height: 300px">
      <a-spin />
    </div>
    <div v-if="data.length>0" class="contenBox">
      <div v-for="layout in pcLayout" :key="layout.id" style="width:19.5%;">
        <div v-for="(item,index) in layout" :key="item.id" class="conten" style="max-height: 700px;overflow: auto;">
          <div class="topBox" :style="{background:item.back}">
            <div class="titleText flex ju-between">
              <span class="flex al-center">
                <img style="width: 18px;height:18px" :src="require(`@/assets/images/businessCenter/icon${item.styleType}.png`)" alt="">
                <span class="m-l1">{{ item.title }}</span>
              </span>
              <a-tooltip>
                <template #title>
                  <div v-html="item.define"></div>
                </template>
                <img class="tipsIcon" src="@/assets/images/newTransport/u60.png" alt="">
              </a-tooltip>
            </div>
            <div v-if="item.subItemList">
              <Meun :msg="item" :modal="modal" :styleType="item.styleType"></Meun>
            </div>
            <div v-else>
              <Meun :msg="item" :styleType="item.styleType"></Meun>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
    <ColorMoadl v-model:visible="colorVisible" :data="data"></ColorMoadl>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref, onBeforeUnmount } from 'vue'
import CountTo from '@/components/countTo'
import Meun from './meun'
import { businessStatistics } from '@/api/businessCenter/businessCenter'
import ColorMoadl from '@/layouts/components/newTransport/components/colorMoadl'
import { message } from 'ant-design-vue'



const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})
const modal = ref(null)
const btnLoading = ref(false)
const settTiming = ref(10)
const colorData = ref([])
const colorVisible = ref(false)
const loadingNum = ref(0)
const loading = ref(false)
const data = ref([])
const pcLayout = ref([])
const dataList = ref([])
const dispatchProvinceList = ref([])
// const reliefList = ref([])
const configVisible = ref(false)
const screen = ref([])
const vehicleNumType = ref(0)


const openColor = () => {
  colorVisible.value = true
}
const openConfig = () => {
  // configVisible.value = true
}

const cancel = () => {
  clearTimer()
  emit('update:visible', false)
}


const reqPageAll = (type) => {
  if (!loadingNum.value) {
    loading.value = true
  }
  btnLoading.value = true
  businessStatistics({
  }).then(res => {
    if (res.code === 10000) {
      settTiming.value = 10
      debounceFn(reqPageAll, settTiming.value)
      data.value = res.data.consoleList
      if (res.data.work) {
        let work = res.data.work
        headList.value.forEach(item => {
          item.value = 0
          if (!item.key) {
            item.children.forEach(opt => {
              if (work?.[opt.key]) {
                opt.value = work[opt.key]
              }
              item.value += Number(opt.value)
            })
          } else {
            if (work?.[item.key]) {
              item.value = work[item.key]
            }
          }

        })
      }
      if (localStorage.getItem('colorData')) {
        colorData.value = JSON.parse(localStorage.getItem('colorData'))
      }
      data.value.forEach(item => {
        item.back = '#fff'
        colorData.value.forEach(opt => {
          if (opt.title === item.title) {
            item.back = opt.back
          }
        })
      })
      let list = []
      res.data.pcLayout.forEach((item) => {
        let arr = []
        item.forEach(opt => {
          arr.push(data.value[opt])
        })
        list.push(arr)
      })
      pcLayout.value = list
    } else {
      if (res.code === 10101) {
        clearTimer()
        return
      }
      settTiming.value = 30
      debounceFn(reqPageAll, settTiming.value)
    }
  }).finally(() => {
    btnLoading.value = false
    loading.value = false
    loadingNum.value = 1
  })
}

// 延时去执行某个函数 （DC）
const DCtimer = []
let timer = null
const debounceFn = (fn, delay) => {
  clearTimer()
  timer = setTimeout(() => {
    if (modal.value) {
      fn()
    }
  }, delay * 1000)
  DCtimer.push(timer)
}

const clearTimer = () => {
  if (DCtimer.length > 0) {
    DCtimer.forEach((item, index) => {
      if (timer === item) {
        DCtimer.splice(index, 1)
      }
      clearTimeout(item)
    })
  }
}
onBeforeUnmount(() => {
  clearTimer()
});
onMounted(() => {
  reqPageAll()
})

const headList = ref([
  {
    title: '台量排名',
    value: 0,
    children: [
      {
        title: '公司',
        key: 'vehicleMonthNumRank',
        value: 0
      },
      {
        title: '部门',
        key: 'vehicleMonthNumOrgRank',
        value: 0
      }
    ]
  },
  {
    title: '私海',
    value: 0,
    children: [
      {
        title: '当月',
        key: 'privateMonthNum',
        value: ''
      },
      {
        title: '今日',
        key: 'privateTodayNum',
        value: ''
      },
      {
        title: '月跟进',
        key: 'privateFollowMonthNum',
        value: ''
      },
      {
        title: '日跟进',
        key: 'privateFollowTodayNum',
        value: ''
      },
    ]
  },
  {
    title: '合作',
    value: 0,
    children: [
      {
        title: '当月',
        key: 'coopMonthNum',
        value: ''
      },
      {
        title: '今日',
        key: 'coopTodayNum',
        value: ''
      },
      {
        title: '月跟进',
        key: 'coopFollowMonthNum',
        value: ''
      },
      {
        title: '日跟进',
        key: 'coopFollowTodayNum',
        value: ''
      },
    ]
  },
  {
    title: '订单推送',
    value: 0,
    children: [
      {
        title: '当月',
        key: 'orderMonthPushNum',
        value: ''
      },
      {
        title: '今日',
        key: 'orderTodayPushNum',
        value: ''
      },
    ]
  },
  {
    title: '车辆推送',
    value: 0,
    children: [
      {
        title: '当月',
        key: 'vehicleMonthPushNum',
        value: ''
      },
      {
        title: '今日',
        key: 'vehicleTodayPushNum',
        value: ''
      },
    ]
  },
  {
    title: '当月利润',
    key: 'profitMonth',
    value: 0
  },

])
</script>

<style lang="less" scoped>
.waterfall {
  position: relative;
}
.waterfall-item {
  position: absolute;
  padding: 10px;
  background: red;
}
.classmodal {
  :deep(.ant-modal-header) {
    padding: 10px !important;
  }
}

.titleBox {
  padding: 10px 20px;
  background: #fff;
  font-weight: normal;
  font-size: 13px;
  border-bottom: 1px solid #eee;
}

.taskInfo {
  width: 84%;
  color: #344563;
}

.contenBox {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.conten {
  margin-bottom: 10px;

  .topBox {
    width: 100%;
    min-height: 100px;
    background: #fff;
    padding: 15px 10px;
    border-radius: 2px;
  }
}

.titleText {
  color: #333;
  font-size: 15px;
  font-weight: bold;
}
.itemBox {
  margin-top: 10px;
  padding: 10px;
  background: #f7f8fc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  .tips {
    font-size: 12px;
    color: #999;
  }
}

.itemTitle {
  padding: 5px 10px;
  background: #f7f8fc;
  border-bottom: 1px solid #fff;
}

.stateStyle {
  padding: 4px 8px;
  background: #edf0f9;
  border-radius: 4px;
  width: 48%;
  margin-bottom: 10px;
  border-left: 5px solid transparent;
}

.arrBox {
  padding: 2px 8px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 12px;
}

.customTags {
  padding: 5px 10px;
  background: #f7f8fc;
  border-bottom: 1px solid #fff;
  width: 49%;
}

.line {
  width: 100%;
  height: 1px;
  background: #eee;
  margin: 8px 0;
}

.outerBox {
  padding: 25px 10px;
  background: #f7f8fc;
  border-radius: 4px;
  margin-top: 10px;
  color: #333;
  font-size: 14px;
}

.tipsIcon {
  width: 16px;
  height: 16px;
}

.custom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.changeMar {
  margin-right: 0;
}

.dropdownBox {
  padding: 10px;
  width: 280px;
  box-shadow: 0 2px 5px #333;
  background: #fff;
}

.head-text {
  font-size: 12px;
  color: #344563;
}
.headVal {
  color: red;
  font-size: 14px;
  font-weight: 700;
}
</style>