<template>
<div >
  <div>
    <Inquiry   />
  </div>
</div>
</template>

<script setup>
import { ref } from 'vue'
import Inquiry from './inquiry'

</script>

<style lang="less" scoped>

</style>