<template>
  <div>
    <a-modal :visible="visible" :title="title" centered footer="" @cancel="cancel" width="92%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
        <div v-for="(item,index) in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.colorType ?'changeItem':''">
          <span v-if="index !== 0"> </span>{{ item.name }}&nbsp;&nbsp;{{ item.total }}
        </div>
      </div>
      <slot name="searchTabel"></slot>
      <SearchList v-if="iptData.length > 0" ref="SearchListRef" :iptData="iptData" @searchChange="searchChange" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
      <div v-if="!isOrder" class="m-t1 tableBox">
        <div class="sys-screenTitle">当前筛选出&nbsp;<a>{{ dataLength }}</a>&nbsp;条</div>
        <a-table ref="tableRef" size="small" :dataSource="data" :rowKey="(item,index) => index" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500,x:x?x:false}">
          <template #sort="{ index }">
            {{ index +1  }}
          </template>
          <template #customerName="{ record }">
            <a v-if="record.customerName " @click="lookDetails(record)">{{ record.customerName }}</a>
            <a v-else @click="lookDetails(record)">无</a>
          </template>
          <template #name="{ record }">
            <a v-if="record.name " @click="lookDetails(record)">{{ record.name }}</a>
            <a v-else @click="lookDetails(record)">无</a>
          </template>
          <template #vinNo="{ record }">
            {{ record.vinNo }}
            <div>{{record.brand}}{{ record.model }}</div>
          </template>
          <template #transStatus="{ record }">
            {{record.transportType?.label}}-{{ record.transStatus?.label }}
          </template>
          <template #customerStatus="{record}">
            <div>
              <a-tag color="#E96B00" v-show="record.coopType">成交-{{ record.coopType?.label }}</a-tag>
              <a-tag color="#E96B00" v-show="record.coopOldType">合作-{{ record.coopOldType?.label }}</a-tag>
            </div>
          </template>
          <template #level="{ record }">
            <div>
              <a-tag color="#D43030" v-show="record.riskGrade">风险-{{ record.riskGrade?.label }}</a-tag>
              <a-tag color="#003470" v-show="record.vehicleGrade">台量-{{ record.vehicleGrade?.label }}</a-tag>
              <a-tag color="#AC33C1" v-show="record.qualityGrade">质量-{{ record.qualityGrade?.label }}</a-tag>
            </div>
          </template>
          <template #mobile="{ record }">
            {{ record.mobile }}
            <span v-if="record.mobile&& record.wechat">/</span>
            {{ record.wechat }}
          </template>
          <template #startAddress="{ record }">
            <div>{{ record.startAddress  }}</div>
            {{ record.endAddress }}
          </template>
          <template #clueLineStart="{ record }">
            <div>{{ record.clueLineStart  }}</div>
            {{ record.clueLineEnd }}
          </template>
          <template #startDetails="{ record }">
            <div>{{ record.startDetails  }}</div>
            {{ record.endDetails }}
          </template>
          <template #intentionLevel="{ record }">
            <a-tag color="#FFA301" v-if="record.intentionLevel">{{record.intentionLevel?.label}}</a-tag>
          </template>
          <template #entrustAdd="{ record }">
            <a @click="inspectionCommissionClick(record)">委托</a>
          </template>
          <template #takeOutAddress="{ record }">  <!-- 送车信息 -->
              {{record.takeOutAddress}} {{record.takeType?.label}}
          </template>
          <template #picker="{ record }">
            {{ record.picker }}  {{ record.pickerMobile }}
          </template>
          <template #follows="{ record }">
            <a-popover title="详细信息">
              <template #content>
                <p style="width: 400px; padding: 0 10px">{{ record.follows }}</p>
              </template>
              <div>
                <div style="
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              ">
                  {{ record.follows }}
                </div>
              </div>
            </a-popover>
          </template>
          <template #orderSettlement="{ record }">
            {{ record.orderSettlement?.label }} 
            <span v-if="record.settlementStatus?.value === 0" style="color: red;"> {{ record.settlementStatus?.label }}</span>
            <span v-else > {{ record.settlementStatus?.label }}</span>
          </template>
          <template #Report="{ record }"> <!-- 报备 -->
            <div v-if="record.auditStatus?.value === 0" class="flex">
              <div style="width:75%">{{ record.reportReason }}</div>
              <a>{{ record.auditStatus.label }}</a>
            </div>
            <a v-else @click="openReport(record)">报备</a>
          </template>
          <template #surchargeAudit="{ record }"> <!-- 附加费审核 -->
            <a @click="setSurchargeAudit(record,1)">审核</a>
          </template>
          <template #abnormalAudit="{ record }"> <!-- 异常审核 -->
            <a @click="setSurchargeAudit(record,2)">审核</a>
          </template>
          <template #Policy="{ record }">
            <a @click="openPolicy(record)">保单</a>
          </template>
          <template #contract="{ record }">
            <a @click="openContract(record)">合同</a>
          </template>
          <template #surplusDays="{ record }">
            <span v-if="record.expireDays>=0" >{{record.expireDays}}天</span>
          </template>
          <template #expireDays="{ record }">
            <span v-if="record.expireDays<0" >{{Math.abs(record.expireDays) }}天</span>
          </template>
          <template #documentaryDetails="{ record }">
            <div>
              <a-popover title="详细信息">
                <template #content>
                  <p style="width:100px;padding: 0 10px;">{{ record.createTime }}</p>
                  <p style="width:300px;padding: 0 10px;">{{ record.documentaryDetails }}</p>
                  <p v-if="record.processContent" style="width:300px;padding: 0 10px;color:#d21165;">最新处理：{{ record.handler + " "+record.processTime + '：' + record.processContent }}</p>
                </template>
                <div style=display:flex>
                  <div style="overflow:hidden;white-space:nowrap;text-overflow: ellipsis;">{{
                  record.createTime + '：'
                }}</div>
                  <div style="width:400px;overflow:hidden;white-space:nowrap;text-overflow: ellipsis;">{{
                  record.documentaryDetails }}
                  </div>
                </div>
                <div v-if="record.processContent">
                  <div style="width:400px;overflow:hidden;color:#d21165;white-space:nowrap;text-overflow: ellipsis;">最新处理：{{
                    record.handler + record.processTime + '：' +record.processContent }}
                  </div>
                </div>
              </a-popover>
            </div>
          </template>
          <template #operate="{ record }">
            <div>
              <a-space>
                <a @click="operateDetail(record)">查看</a>
              </a-space>
            </div>
          </template>
          <template #cancelFollow="{ record }">
            <a @click="cancelFollowAdd(record)">取消关注</a>
          </template>
          <template #exceptionFileUrl="{ record }">
            <a v-if="record.fileId" @click="lookImg(record)">查看</a>
          </template>
          <template #settableAmt="{ record }">
            <span v-if="record.settableAmt > 0" style="color:red">{{ record.settableAmt }}</span>
            <span v-else>{{ record.settableAmt }}</span>
          </template>
          <template #overdueDay="{ record }">
            <span v-if="record.overdueDay > 0" style="color:red">{{ record.overdueDay }}</span>
            <span v-else>{{ record.overdueDay }}</span>
          </template>
          <template #insuranceCompany="{ record }">
            {{ record.insuranceCompany?.label }} &nbsp;&nbsp;
            {{ record.insuranceFee }}万元&nbsp;&nbsp;
            {{ record.insurancePremium }}元
          </template>
          <template #startTimeHT="{ record }">
            {{ record.startTime }}
            <div>{{ record.endTime }}</div>
          </template>
          <template #documentaryDetailsArr="{ record }">
           <span v-if="record.documentaryTime"> {{  record.documentaryTime}}:</span>
           {{ record.documentaryDetails }}
          </template>
          <template #gpsTime="{ record }">
            {{ record.gpsTime }}  {{ record.nowLocation }}
          </template>
          <template #settlementCycle="{ record }">
            <span v-if="record.settlementMode?.value === 2&&record.settlementCycle">
              {{ record.settlementCycle }}月
            </span>
            <span v-if="record.settlementMode?.value === 3&&record.settlementCycle">
              {{ record.settlementCycle }}天
            </span>
          </template>
        </a-table>
      </div>

      <div v-else class="m-t1 ">
        <OrderTable @addFollowNew="addFollowNew" :showType="showType" :loading="loading" @changePagination="changePagination" :pagination="pagination" :dataLength="dataLength" :listData="data"></OrderTable>
      </div>
    </a-modal>

    <!-- 合同查看 -->
    <OrderInfo :isDownload="true" @download="download" ref="OrderInfoRef"></OrderInfo>
    <!-- 保单查看 -->
    <insurances ref='insurancesRef' @success="success" />
    <!-- 客户详情 -->
    <a-drawer title="详情" width="80%" placement="right"  v-model:visible="detailShow" destroyOnClose>
      <CustomerDetail v-model:customerId="customerId" :type="detailType" />
    </a-drawer>
    <!-- 审核 -->
    <a-modal v-model:visible="reviewVisible" title="审核" :confirmLoading="btnLoading" @ok="submitAdd" destroyOnClose>
      <FormList @getVal="getVal" ref="FormListRef" :fromList="fromList"></FormList>
    </a-modal>
    <!-- 报备 -->
    <a-modal v-model:visible="reportVisible" title="报备" :confirmLoading="btnLoading" @ok="FormListRefBB.getValRef()" destroyOnClose>
      <FormList @getVal="getValBB" ref="FormListRefBB" :fromList="fromListBB"></FormList>
    </a-modal>
    <!-- 跟单记录 -->
    <a-modal v-model:visible="followShow" width="60%" footer="" :bodyStyle="{ padding: '5px' }">
      <template #title>
        <div>
          <h3>跟单-{{ record.vinNo }}</h3>
        </div>
      </template>
      <ResultFollow ref="endFollowRef" />
    </a-modal>
    <!-- 委托 -->
    <InspectionCommission ref="inspectionCommission" />
    <!-- 查看图片 -->
    <PictureViewer v-model:visible="isImgShow" :lookImgID="lookImgID"></PictureViewer>
  </div>
</template>
  
  <script setup>
import { onMounted, ref, reactive, nextTick } from 'vue'
import SearchList from '@/components/searchList'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import OrderTable from './orderTable'
import OrderInfo from '@/views/order/positionInfo/orderInfo'
import CustomerDetail from '@/views/crmManagement/tool/customerDetail/index.vue'
import insurances from '../../components/insurance.vue'
import { analysisAudit, checkOperate } from '@/api/transport/transport'
import { uninsuredAdd, focusCancel } from '@/api/businessCenter/businessCenter'
import FormList from '@/components/formList'
import ResultFollow from '@/views/components/followOrder'
import { cloneDeep } from 'lodash-es'
import PictureViewer from '@/components/PictureViewer'
import { transitionTimeYMD, transitionTimeYM, addressTranString, addressTran } from '@/utils/util'
import InspectionCommission from '@/views/components/inspectionCommission'
const store = useStore()
const emit = defineEmits(['update:visible', 'searchAdd', 'resetBtn', 'setRemaekOk', 'download', 'success', 'changePagination', 'addFollowNew', 'changeBtn','searchChange'])
const props = defineProps({

  pagination: {
    type: Object,
    default: {}
  },
  x: {
    type: Number,
    default: 0
  },
  item: {
    type: Object,
    default: {}
  },
  title: {
    type: String,
    default: ''
  },
  visible: {
    type: Boolean,
    default: false
  },
  iptData: {
    type: Array,
    default: () => []
  },
  data: {
    type: Array,
    default: () => []
  },
  columns: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  totalClueNum: {
    type: Number,
    default: 0
  },
  subItemList: {
    type: Object,
    default: []
  },
  dataLength: {
    type: Number,
    default: 0
  },
  colorIdx: {
    type: Number,
    default: null
  },
  isAll: {
    type: Boolean,
    default: false
  },
  isOrder: {
    type: Boolean,
    default: false
  },
  keyType: {
    type: String,
    default: 'pageType'
  }
})

const tableRef = ref(null)
const OrderInfoRef = ref(null)
const detailShow = ref(false)
const customerId = ref(null)
const insurancesRef = ref(null)
const reviewVisible = ref(false)
const btnLoading = ref(false)
const FormListRef = ref(null)
const record = ref({})
const auditType = ref(null)
const reportVisible = ref(false)
const FormListRefBB = ref(null)
const followShow = ref(false)
const endFollowRef = ref(null)
const inspectionCommission = ref(null)
const detailType = ref(null)
const lookImgID = ref([])
const isImgShow = ref(false)
const SearchListRef = ref(null)

const searchChange = (e) => {
  emit('searchChange',e)
}
//查看图片
const lookImg = (record) => {
  if(!record.fileId) return
  lookImgID.value = [record.fileId]
   isImgShow.value = true
}
//取消关注
const cancelFollowAdd = (record) => {
  focusCancel({
    focusType: record.focusType.value,
    objectId: record.objectId
  }).then( res => {
    if (res.code !== 10000) return
    message.success('取消关注成功')
   emit('success',record.objectId,'objectId')
  })
}

// 委托
const inspectionCommissionClick = record => {
  inspectionCommission.value.onVisible(true)
  inspectionCommission.value.onInspectionCommission(record.orderVehicleId)
}
const addFollowNew = () => {
  emit('addFollowNew')
}
//跟单记录
const operateDetail = item => {
  followShow.value = true
  record.value = item
  setTimeout(() => {
    endFollowRef.value.loadData(item.orderVehicleId)
  })
}

// 报备
const getValBB = (e) => {
  btnLoading.value = true
  uninsuredAdd({
    orderVehicleId: record.value.orderVehicleId,
    ...e
  }).then(res => {
    if (res.code !== 10000) return
    message.success('报备成功')
    reportVisible.value = false
    props.data.forEach(item => {
      if (item.orderVehicleId === record.value.orderVehicleId) {
        item.reportReason = e.reportReason
        item.auditStatus = {
          value: 0,
          label: '待审核'
        }
      }
    })

  }).finally(() => {
    btnLoading.value = false
  })
}
const openReport = (item) => {
  record.value = item
  reportVisible.value = true
}
//附加费审核
const getVal = (e) => {
  if (e.auditStatus === 2) {
    if (!e.auditRemark) {
      message.error('请填写备注')
      return
    }
  }
  btnLoading.value = true
  let url = null
  url = auditType.value === 1 ? checkOperate  : analysisAudit
  url({
    id: record.value.id,
    ...e
  }).then(res => {
    if (res.code !== 10000) return
    message.success('审核成功')
    reviewVisible.value = false
    emit('success', record.value.id, 'id')
  }).finally(() => {
    btnLoading.value = false
  })
}
const success = (id) => {
  emit('success', id, 'orderId')
}
const submitAdd = () => {
  FormListRef.value.getValRef()
};
const setSurchargeAudit = (item, type) => {
  auditType.value = type
  record.value = item
  reviewVisible.value = true
}
const changePagination = (e) => {
  emit('changePagination', e)
}
const lookDetails = (record) => {
  if(record.customerClass){
    detailType.value = String(record.customerClass.value - 1) 
  }else{
    detailType.value = '2'
  }
  detailShow.value = true
  customerId.value = record.customerId
}
const download = (e) => {
  emit('download', e)
}
const openPolicy = (record) => {
  insurancesRef.value.insuranceDetail(record.orderId, true)

}
const openContract = (record) => {
  OrderInfoRef.value.onmodalVisible('', 1, record.orderId)
}



const cancel = () => {
  emit('update:visible', false)
}
const indexKey = ref(null)

const searchAdd = (e) => {
  console.log(e);
  let msg = cloneDeep(e)
  if (msg.reportTimeStart) {
    msg.reportTimeStart = transitionTimeYMD(msg.reportTimeStart)
  }
  if (msg.reportTimeEnd) {
    msg.reportTimeEnd = transitionTimeYMD(msg.reportTimeEnd)
  }
  if (msg.explainTimeEnd) {
    msg.explainTimeEnd = transitionTimeYMD(msg.explainTimeEnd)
  }
  if (msg.explainTimeStart) {
    msg.explainTimeStart = transitionTimeYMD(msg.explainTimeStart)
  }
  if (msg.pushTimeStart) {
    msg.pushTimeStart = transitionTimeYMD(msg.pushTimeStart)
  }
  if (msg.pushTimeEnd) {
    msg.pushTimeEnd = transitionTimeYMD(msg.pushTimeEnd)
  }
  if (msg.pushStartTime) {
    msg.pushStartTime = transitionTimeYMD(msg.pushStartTime)
  }
  if (msg.pushEndTime) {
    msg.pushEndTime = transitionTimeYMD(msg.pushEndTime)
  }
  if (msg.startAddress) {
    msg.startAddress = addressTran(msg.startAddress)
  }
  if (msg.endAddress) {
    msg.endAddress = addressTran(msg.endAddress)
  }
  if (msg.startAddressString) {
    msg.startAddress = addressTranString(msg.startAddressString)
    delete msg.startAddressString
  }
  if (msg.endAddressString) {
    msg.endAddress = addressTranString(msg.endAddressString)
    delete msg.endAddressString
  }
  if (msg.startCitys) {
    msg.startCitys = addressTranString(msg.startCitys)
  }
  if (msg.endCitys) {
    msg.endCitys = addressTranString(msg.endCitys)
  }
  if (msg.startTime) {
    msg.startTime = transitionTimeYMD(msg.startTime)
  }
  if (msg.endTime) {
    msg.endTime = transitionTimeYMD(msg.endTime)
  }
  emit('searchAdd', msg)
}
const resetBtn = () => {
  emit('resetBtn')
}
const changeBtn = (e) => {
  indexKey.value = e.colorType
  emit('changeBtn', e.colorType, props.item.pageType)
}


const tabstatus = ref([
  {
    name: '全部',
    id: 1,
    total: 0,
    colorType: null
  },
])
function addEvent (element, eventType, callback) {
  if (element.addEventListener) {
    element.addEventListener(eventType, callback, false);
  } else if (element.attachEvent) {  // 兼容IE8及以下
    element.attachEvent('on' + eventType, callback);
  } else {
    element['on' + eventType] = callback;  // 兼容IE使用旧式方法
  }
}


onMounted(() => {
  if (props.subItemList.length > 0) {
    tabstatus.value[0].total = props.totalClueNum
    let list = []
    props.subItemList.forEach((opt) => {
      let msg = {
        name: opt.title,
        total: opt.num,
        colorType: opt[props.keyType]
      }
      if (props.isAll) {
        tabstatus.value.push(msg)
      } else {
        list.push(msg)
        tabstatus.value = list
      }
    })
  } else {
    tabstatus.value = []
  }
  indexKey.value = props.colorIdx
  nextTick(() => {
    setTimeout(() => {
      if (tableRef.value) {
        const tableBody = tableRef.value.$el.querySelector('.ant-table-body');
        addEvent(tableBody, 'scroll', (e) => {
          const { scrollTop, clientHeight, scrollHeight } = e.target;
          if (scrollTop + clientHeight >= scrollHeight - 100) {
            // 滚动条触底，执行加载数据的操作
            emit('theBottom')
          }
        })
      }
    }, 1000)
  })

})
defineExpose({
  SearchListRef
})
const fromList = ref([
  {
    label: "审核结果",
    name: "auditStatus",
    type: "select",
    placeholder: "请选择",
    value: null,
    prop: "auditStatus",
    width: "100%",
    opt: [
      {
        name: "通过",
        id: 1
      },
      {
        name: "不通过",
        id: 2
      }
    ],
    rules: [{ required: true, message: "请选择", trigger: "blur", type: "number" }],
  },
  {
    label: "备注",
    name: "auditRemark",
    type: "textarea",
    placeholder: "填写备注",
    value: null,
    prop: "auditRemark",
    width: "100%",
    rows: 3
  },
])
const fromListBB = ref([
  {
    label: "原因",
    name: "reportReason",
    type: "textarea",
    placeholder: "填写备注",
    value: null,
    prop: "reportReason",
    width: "100%",
    rows: 3,
    rules: [{ required: true, message: "请填写", trigger: "blur", type: "string" }],
  },
])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  height: 620px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
}

.remark-content {
  width: 80%;
  max-height: 80px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.isExpatriate {
  min-width: 16px;
  height: 16px;
  background-color: rgba(57, 106, 252, 1);
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
.ant-typography {
  margin: 0;
}
</style>