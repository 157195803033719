<template>
  <div>
    <div class="flex" style="padding:20px 0">
      <a-select  v-model:value="value"  style="width: 200px" :options="options" @change="handleChange">
      </a-select>
      <div style="width:10px;"></div>
      <a-input v-model:value="keyword" :placeholder="placeholder"></a-input>
    </div>
    <div>
      <a-button @click="btnAdd" type="primary">搜索</a-button>
    </div>
    <a-modal v-model:visible="visible" title="快速查车" destroyOnClose width="80%" footer="" :bodyStyle="{ padding:'10px',background:'#EEE' }">
      <CarList :keyword="keyword" :typeNum="value"></CarList>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import CarList from './carList.vue';
import { message } from 'ant-design-vue';
const options = ref([
  { label: '车牌/车架号', value: 0 },
  { label: '订单号', value: 1 },
  { label: '客户电话', value: 2 }
])
const visible = ref(false)
const placeholder = ref('车牌/车架号')
const value = ref(0)
const keyword = ref('')

const handleChange = (e,v) => {
  placeholder.value = v.label
}
const btnAdd = () => {
  switch (value.value) {
        case 0:
          if(keyword.value.length >= 4)   visible.value = true
          else message.error('请输入至少4位车牌号')
          break
        case 1:
          if(keyword.value.length >= 4)   visible.value = true
          else message.error('请输入至少8位订单号')
          break
        case 2:
          if(keyword.value.length >= 4)   visible.value = true
          else message.error('请输入至少4位电话号码')
          break
      }

}

</script>

<style lang="less" scoped>
</style>