<style lang="less" scoped>
</style>
<template>
  <div>
    <a-drawer :z-index="100000" title="保单详情" placement="right" :closable="false" width="80%" v-model:visible="visible">
      <a-button type="primary" @click="downloadAdd">下载</a-button>
      <iframe v-if="insuranceData.fileUrl != null && insuranceData.fileUrl.length > 1" :src="insuranceData.fileUrl" :style="iframeStyle"></iframe>
    </a-drawer>
  </div>
</template>
<script>
import { toRefs, reactive } from 'vue'
import { detail } from '@/api/system/insurance'
import { carPicsGroup, fileDownload } from '@/api/global'
import { insuranceDw } from '@/api/businessCenter/businessCenter'
import { message } from 'ant-design-vue'
export default {
  props: {

  },
  setup (props,{ emit }) {
    const state = reactive({
      visible: false,
      iframeStyle: {
        width: '100%',
        height: '100%'
      },
      orderId: '',
      insuranceData: {}
    })

    const insuranceDetail = (orderId, visible) => {
      state.orderId = orderId
      state.visible = visible
      detail(orderId).then(res => {
        if (res.code === 10000) {
          state.insuranceData = res.data
          getHeight()
        }
      })
    }
    const downloadAdd = () => {
      fileDownload({
        url: state.insuranceData.fileUrl
      }, state.insuranceData.fileUrl,
      'pdf'
      ).then(res =>{
        insuranceDw(
         state.orderId
        ).then( res => {
          message.success('下载成功')
          emit('success',state.orderId)
        })
      })

    }

    const getHeight = () => {
      state.iframeStyle.height = window.innerHeight - 64 - 68 + 'px'
    }
    return {
      ...toRefs(state),
      insuranceDetail,
      downloadAdd
    }
  }
}
</script>
