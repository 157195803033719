<template>
  <div>
    <div class="m-t1 tableBox">
    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" bordered size="small" :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #orderId="{ record }">
        <a-typography-paragraph :copyable="{ text: record.id }">
          {{ record.id }}
        </a-typography-paragraph>
      </template>
      <template #operation="{ record }">
        <div>
          <a @click="detailNavigate(record.id)">详情</a>
        </div>
      </template>
    </a-table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { page } from '@/api/trans/order'
import { useRouter } from 'vue-router'
const router = useRouter()
const props = defineProps({
  typeNum:{
    type: Number,
    default: 0
  },
  keyword:{
    type: String,
    default: ''
  }
})
const loading = ref(false)
const listData = ref([])
const pagination = ref({
  current: 1,
  size: 10,
  total: null,
  showTotal: (total, range) => `共 ${total} 条`
})

const detailNavigate = id => {
      const routeData = router.resolve({
        name: 'orderDetail',
        params: {
          id: id
        }
    });
    // 打开新窗口或标签页
    window.open(routeData.href, '_blank');
    }
const handleTableChange = (pag, filters, sorter) => {
  pagination.value.current = pag.current
  pagination.value.pageSize = pag.pageSize
  loadData()
}



const loadData = () => {
  loading.value = true
  page({
    vinNo: props.typeNum === 0 ? props.keyword : '',
    orderId: props.typeNum === 1 ? props.keyword : '',
    customerMobile: props.typeNum === 2 ? props.keyword : '',
    current: pagination.value.current,
    size: pagination.value.pageSize
  }).then(res => {
    // console.log(res)  // 主要动态数据
    if (res.code === 10000) {
      listData.value = res.data.records
      pagination.value.total = res.data.total
    }
  }).finally(() => {
    loading.value = false
  })
}
const columns = ref([
  { title: '订单号', dataIndex: 'orderId', slots: { customRender: 'orderId' } },
  { title: '客户名称', dataIndex: 'customerName' },
  { title: '始发地', dataIndex: 'startAddress' },
  { title: '目的地', dataIndex: 'endAddress' },
  { title: '所属区域', dataIndex: 'officeOrgName' },
  { title: '结算方式', dataIndex: 'orderSettlement.label' },
  { title: '结算状态', dataIndex: 'settlementStatus.label' },
  { title: '创建时间', dataIndex: 'orderTime' },
  { title: '业务员', dataIndex: 'salesman' },
  { title: '产品', dataIndex: 'productName' },
  { title: '状态', dataIndex: 'orderStatus.label' },
  { title: '操作', dataIndex: 'operation', width: 200, slots: { customRender: 'operation' } }
])
onMounted(() => {
  loadData()
})
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>