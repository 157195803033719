import { ref } from 'vue'

//  1今日待跟进 2首次待跟进 3重复线索 4超时未跟进 5超时未成交 6一天内掉落
export const priSeaiptData1 = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "select",
    placeholder: "意向等级",
    value: null,
    prop: "intentionLevel",
    width: 200,
    key: 'value',
    keyVal:'label',
    opt: []
  },
  {
    type: "selectSearch",
    placeholder: "业务员",
    value: null,
    opt:[],
    prop: "saleId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "跟进信息",
    value: null,
    prop: "followContent",
    width: 200,
  },

])
export const priSeacolumns1 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    slots: {
      customRender: 'customerName'
    },
    align: 'center',
  },
  {
    title: '电话/微信号',
    dataIndex: 'mobile',
    slots: {
      customRender: 'mobile'
    },
    align: 'center',
  },
  {
    title: '客户类型',
    dataIndex: 'customerType.label',
    align: 'center',
  },
  {
    title: '意向等级',
    dataIndex: 'intentionLevel',
    slots: {
      customRender: 'intentionLevel'
    },
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '领取时间',
    dataIndex: 'enterPrivateTime',
    align: 'center',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'saleName',
    align: 'center',
  },
  {
    title: '线索分类',
    dataIndex: 'clueType.label',
    align: 'center',
  },
  {
    title: '客户来源',
    dataIndex: 'customerSourceType.label',
    align: 'center',
  },
  {
    title: '下次跟进时间',
    dataIndex: 'nextFollowTime',
    align: 'center',
  },
  {
    title: '起点-终点',
    dataIndex: 'clueLineStart',
    slots:{
      customRender:'clueLineStart'
    },
    align: 'center',
  },
  {
    title: '提醒时间',
    dataIndex: 'remindDate',
    align: 'center',
  },

  {
    title: '线索跟进信息',
    dataIndex: 'follows',
    slots:{
      customRender:'follows'
    },
    width:200,
    align: 'center',
  },

])

export const cooperiptData1 = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "select",
    placeholder: "意向等级",
    value: null,
    prop: "intentionLevel",
    width: 200,
    key: 'value',
    keyVal:'label',
    opt: []
  },
  {
    type: "selectSearch",
    placeholder: "业务员",
    value: null,
    prop: "saleId",
    opt:[],
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "跟进信息",
    value: null,
    prop: "followContent",
    width: 200,
  },
])
export const coopercolumns1 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
    slots: {
      customRender: 'customerName'
    },
  },
  {
    title: '电话/微信号',
    dataIndex: 'mobile',
    slots: {
      customRender: 'mobile'
    },
    align: 'center',
  },
  {
    title: '客户类型',
    dataIndex: 'customerType.label',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'saleName',
    align: 'center',
  },
  {
    title: '意向等级',
    dataIndex: 'intentionLevel',
    slots: {
      customRender: 'intentionLevel'
    },
    align: 'center',
  },
  {
    title: '客户状态',
    dataIndex: 'customerStatus',
    slots: {
      customRender: 'customerStatus'
    },
    align: 'center',
  },
  {
    title: '客户等级',
    dataIndex: 'level',
    slots: {
      customRender: 'level'
    },
    align: 'center',
  },
  {
    title: '客户来源',
    dataIndex: 'customerSourceType.label',
    align: 'center',
  },
  {
    title: '提醒时间',
    dataIndex: 'remindDate',
    align: 'center',
  },
  {
    title: '领取时间',
    dataIndex: 'enterPrivateTime',
    align: 'center',
  },
  {
    title: '合作时间',
    dataIndex: 'enterCoopTime',
    align: 'center',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    align: 'center',
  },
 
  {
    title: '最近下单时间',
    dataIndex: 'orderLastTime',
    align: 'center',
  },
  {
    title: '下次跟进时间',
    dataIndex: 'nextFollowTime',
    align: 'center',
  },
  {
    title: '线索跟进信息',
    dataIndex: 'follows',
    slots:{
      customRender:'follows'
    },
    align: 'center',
  },

])
