<template>
  <div>
    <div class="flex wrap al-center serchBox">
      <div v-for=" item in groupListData" class="m-r1 itemBox cur-p m-b1" @click="celItem(item)" :class="item.id === activeId ? 'active' : ''" :key="item.id">
        {{ item.name }}
      </div>
      <a @click="newAdd" class="m-b1">+创建分组</a>
    </div>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">当前共&nbsp;<a>{{ data.length }}</a>&nbsp;条</div>
      <div v-if="info" class="info">
        <span>{{ info.customerClass?.label }}</span>
        <span v-if="info.intentionLevel">，{{ info.intentionLevel.label }}</span>
        <span v-if="info.customerType">，{{ info.customerType.label }}</span>
        <span v-if="info.isWechat === 0">，未加微信</span>
        <span v-if="info.isWechat === 1">，已加微信</span>
        <span v-if="info.coopType">，{{ info.coopType.label  }}</span>
        <span v-if="info.vehicleGrade">，{{ info.vehicleGrade.label  }}</span>
        <span v-if="info.follows?.length>0">
          ，
          <span v-for="(item,index) in info.follows" :key="item.id">
            <span v-if="index>0">，</span>
            {{ item }}
          </span>
        </span>
        <span v-if="info.tagTypes?.length>0">
          ，
          <span v-for="(item,index) in info.tagTypes" :key="item.id">
            <span v-if="index>0">，</span>
            {{ item.label }}
          </span>
        </span>
        <a-popconfirm title="确定删除吗?" ok-text="确定" cancel-text="取消" @confirm="confirm">
          <span class="m-l1 cur-p" style="color:red">删除组</span>
        </a-popconfirm>
      </div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #mobile="{ record }">
          {{ record.mobile }}
          <span v-if="record.wechat&&record.mobile">/</span>
          {{ record.wechat }}
        </template>
        <template #customerName="{ record }">
           <span v-if="record.customerClass.value === 3 || record.customerClass.value === 2">
            <a v-if="record.customerName " @click="lookDetails(record)">{{ record.customerName }}</a>
            <a v-else @click="lookDetails(record)">无</a>
           </span>
           <span v-else>{{ record.customerName }}</span>
          </template>
      </a-table>
    </div>
    <a-modal v-model:visible="visible" title="创建分组" @ok="addGroup" destroyOnClose width="38%">
      <GroupingModal @success="success" ref="groupingModalRef"></GroupingModal>
    </a-modal>
     <!-- 客户详情 -->
     <a-drawer title="详情" width="80%" placement="right"  v-model:visible="detailShow" destroyOnClose>
      <CustomerDetail v-model:customerId="customerId" :type="detailType" />
    </a-drawer>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import moment from 'moment'
import { transitionTimeYM } from '@/utils/util'
import { groupList, groupCustomer, groupDelete } from '@/api/businessCenter/businessCenter'
import GroupingModal from './groupingModal'
import { getTableBody } from '@/utils/util'
import { message } from 'ant-design-vue'
import CustomerDetail from '@/views/crmManagement/tool/customerDetail/index.vue'

const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const groupListData = ref([])
const pageSize = ref(50)
const visible = ref(false)
const groupingModalRef = ref(null)
const activeId = ref(null)
const tableRef = ref(null)
const info = ref(null)
const detailShow = ref(false)
const customerId = ref(null)
const detailType = ref(null)

const lookDetails = (record) => {
  if(record.customerClass){
    detailType.value = String(record.customerClass.value - 1) 
  }else{
    detailType.value = 3
  }
  detailShow.value = true
  customerId.value = record.customerId
}
const confirm = () => {
  groupDelete({
    id: info.value.id
  }).then(res => {
    if (res.code !== 10000) return
    message.success('删除成功')
    info.value = null
    getGroupList()
    pageSize.value = 50
    data.value = []
    dataSource.value = []
  })
}
const celItem = (item) => {
  activeId.value = item.id
  info.value = item
  groupCustomerList(item.id)
}
const success = () => {
  visible.value = false
  getGroupList()
}
const addGroup = () => {
  groupingModalRef.value.getData()
}
const newAdd = () => {
  visible.value = true
}
const getGroupList = () => {
  groupList({}).then(res => {
    if (res.code !== 10000) return
    groupListData.value = res.data
  })
}
const groupCustomerList = (id) => {
  loading.value = true
  groupCustomer({
    id: id
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)

}
onMounted(() => {
  getGroupList()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})

const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
    slots: {
      customRender: 'customerName'
    },
  },
  {
    title: '电话/微信',
    dataIndex: 'mobile',
    slots: {
      customRender: 'mobile'
    },
    align: 'center',
  },
  {
    title: '客户类型',
    dataIndex: 'customerType.label',
    align: 'center',
  },
  {
    title: '下次跟进时间',
    dataIndex: 'nextFollowTime',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '领取时间',
    dataIndex: 'enterPrivateTime',
    align: 'center',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'saleName',
    align: 'center',
  },
  {
    title: '线索分类',
    dataIndex: 'clueType.label',
    align: 'center',
  },
  {
    title: '线索来源',
    dataIndex: 'customerSourceType.label',
    align: 'center',
  },
  {
    title: '跟进信息',
    dataIndex: 'follows',
    slots: {
      customRender: 'follows'
    },
    align: 'center',
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
.serchBox {
  padding: 20px;
  background: #fff;
}
.itemBox {
  padding: 8px 15px;
  border-radius: 4px;
  color: #344563;
  background: #eeeeee;
}
.active {
  background: #0066cc;
  color: #fff;
}
.info {
  color: #6b778c;
}
</style>