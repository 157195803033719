<template>
  <div>
    <div v-for="item in subItemList" :key="item.id">
      <div class="contenBox">
        <div class="title flex ju-between al-center">
          <span>{{ item.title }} <span v-if="!item.all">
              <span class="cur-p" @click="openAll(null)">
                <CountTo color="#0066CC" fontSize="14px" :endVal="item.num" />
              </span>
            </span></span>
          <div v-if="item.all" @click="openAll(item)" class="all cur-p">全部</div>
        </div>
        <div v-if="item.subItemList" class="flex ju-between wrap" style="padding: 0px 10px 0px 10px">
          <div v-for="opt in item.subItemList" :key="opt.id" class="itemBox m-b1 flex ju-between" style="width: 49%">
            {{ opt.title }}
            <a @click="lookDetail(item,opt)">{{ opt.num }}</a>
          </div>
        </div>
        <div class="tips" v-if="item.subTitle">
          {{item.subTitle}}
        </div>
        <div v-if="item.buttonList" class="flex" style="padding: 0px 0px 10px 10px">
          <div v-for="btn in item.buttonList" :key="btn.id" class="buttonBox m-r1">
            <a-button type="primary" size="small" @click="btnClick(btn)">
              {{ btn.label }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <template v-if="visible">
      <Table v-model:visible="visible" :title="title" @theBottom='theBottom' :x="x" @setRemaekOk="setRemaekOk" @lookDetails="lookDetails"  @searchChange="searchChange"
      :colorIdx="colorIdx" :item="item" :dealID="dealID" @changeBtn="changeBtn" :loading="loading" :dataLength="data.length" @searchAdd="searchAdd"
       @resetBtn="resetBtn" :iptData="iptData" :columns="columns" :data="dataSource" :subItemList="subItemListData">
      </Table>
    </template>

    <a-modal v-model:visible="searchPhoneShow" width="30%" @ok="onPhone" @cancel="phone = ''">
      <template #title>
        <span class="title">领取主叫客户</span>
      </template>
      <a-form layout="vertical" :model="formState">
        <a-form-item label="请输入客户手机号">
          <a-input v-model:value="phone" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model:visible="comSeaVisible" title="公海客户" destroyOnClose width="95%" footer="" :bodyStyle="{padding:'10px',background:'#EEE'}">
      <ComSea />
    </a-modal>
    <a-modal v-model:visible="priSeaVisible" title="私海客户" destroyOnClose width="95%" footer="" :bodyStyle="{padding:'10px',background:'#EEE'}">
      <PrivateSea />
    </a-modal>
    <a-modal v-model:visible="cooperVisible" title="合作客户" destroyOnClose width="95%" footer="" :bodyStyle="{padding:'10px',background:'#EEE'}">
      <CooperativeCustomers />
    </a-modal>
    <a-modal v-model:visible="temporaryVisible" title="临时客户" destroyOnClose width="95%" footer="" :bodyStyle="{padding:'10px',background:'#EEE'}">
      <TemporaryCustomer />
    </a-modal>
    <a-modal v-model:visible="invalidVisible" title="无效客户" destroyOnClose width="95%" footer="" :bodyStyle="{padding:'10px',background:'#EEE'}">
      <InvalidCustomer />
    </a-modal>
  </div>
</template>
  
  <script setup>
import { ref, reactive } from 'vue'
import CountTo from '@/components/countTo'
import {
  receiveOneClick,
  call,
  page as comSeaPage,
} from '@/api/crmManagement/comSea'
import {
  priSeaiptData1, priSeacolumns1,
  cooperiptData1, coopercolumns1,
} from './customerPool'
import Table from '../table'
import { message } from 'ant-design-vue';
import { useStore } from 'vuex'
import ComSea from '@/views/crmManagement/comSea'
import { employeeList } from '@/api/crmManagement/setRules'
import PrivateSea from '@/views/crmManagement/privateSea'
import CooperativeCustomers from '@/views/crmManagement/cooperativeCustomers'
import TemporaryCustomer from '@/views/crmManagement/temporaryCustomer'
import InvalidCustomer from '@/views/crmManagement/invalidCustomer'
import { customerFollowDetails } from '@/api/businessCenter/businessCenter'
import { IfNull, debounce } from '@/utils/util'

const store = useStore()
const props = defineProps({
  subItemList: {
    type: Array,
    default: []
  },
  modal: {}
})
const visible = ref(false)
const title = ref('')
const x = ref(0)
const colorIdx = ref(0)
const loading = ref(false)
const data = ref([])
const dataSource = ref([])
const iptData = ref([])
const columns = ref([])
const comSeaVisible = ref(false)
const priSeaVisible = ref(false)
const cooperVisible = ref(false)
const temporaryVisible = ref(false)
const invalidVisible = ref(false)
const subItemListData = ref([])
const allValue = ref(0)
const pageSize = ref(50)
const pageType = ref(null)
const CDMRef = ref(null)
const searchMsg = ref({})
const state= reactive({
  timer:null
})

const searchChange = (e) => {
  if(!IfNull(e)) return
  debounce(state,getEmployeeList,1000,e)
}
const getEmployeeList = (e) => {
  employeeList({
    name: e,
  }).then(res => {
    if (res.code === 10000) {
      let list = []
      list = res.data.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
      iptData.value.forEach( opt =>{
        if(opt.prop === 'saleId'){
          opt.opt = list
        }
      })
    }
  })
}
const lookDetails = (e) => {
  CDMRef.value.open(e.customerId)
}
const changeBtn = (e) => {
  if (allValue.value === 2) {
    pageType.value = e
    getCustomerFollowDetails()
  }
  if (allValue.value === 3) {
    pageType.value = e
    getCustomerFollowDetails()
  }
}
const lookDetail = (item, opt) => {
  searchMsg.value = {}
  allValue.value = item.all.value
  if (item.all.value === 2) {
    iptData.value = priSeaiptData1.value
    columns.value = priSeacolumns1.value
   x.value = 0
  }
  if (item.all.value === 3) {
    iptData.value = cooperiptData1.value
    columns.value = coopercolumns1.value
    x.value = 2000
  }
  setOpt()
  pageType.value = opt.pageType
  getCustomerFollowDetails()
  visible.value = true
  title.value = item.title
  colorIdx.value = opt.pageType
  subItemListData.value = item.subItemList

}
// 打开全部
const openAll = (item) => {
  if (!item) {
    comSeaVisible.value = true
    return
  }
  switch (item.all.value) {
    case 2:
      priSeaVisible.value = true
      break;
    case 3:
      cooperVisible.value = true
      break;
    case 4:
      temporaryVisible.value = true
      break;
    case 5:
      invalidVisible.value = true
      break;
  }
}


const getCustomerFollowDetails = () => {
  dataSource.value = []
  pageSize.value = 50
  loading.value = true
  customerFollowDetails({
    subType: pageType.value,
    ...searchMsg.value
  }).then((res) => {
    if (res.code !== 10000) return
    data.value = res.data
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const searchAdd = (e) => {
  searchMsg.value = e
  getCustomerFollowDetails()
}
const resetBtn = () => {
  searchMsg.value = {}
  getCustomerFollowDetails()
}


const searchPhoneShow = ref(false)
const phone = ref('')
const btnClick = (btn) => {
  if (btn.value === 1) {
    onOneClickReceive()
  }
  if (btn.value === 2) {
    searchPhoneShow.value = true
  }
}
// 主叫领取客户
const onPhone = () => {
  call({ phone: phone.value }).then((res) => {
    if (res.code === 10000) {
      message.success(res.msg)
      searchPhoneShow.value = false
    }
  })
}
// 一键领取
const onOneClickReceive = () => {
  receiveOneClick({}).then((res) => {
    if (res.code === 10000) {
      message.success(res.msg, '页面数据稍后更新')
    }
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
const setOpt = () => {
  iptData.value.forEach(item => {
    if (item.prop === 'orderSettlement') {
      item.opt = store.state.enumAll.orderSettlementList
    }
    if (item.prop === 'orgId') {
      item.opt = store.state.app.orgList
    }
    if (item.prop === 'exceptionType') {
      item.opt = store.state.enumAll.exceptionExplain
    }
    if(item.prop === 'saleId'){
      item.opt = []
    }
    if(item.prop === 'intentionLevel'){
      item.opt =store.state.app.labelAll.INTENTION_LEVEL
    }
    if (item.prop === 'settlementMode') {
      item.opt = store.state.enumAll.CustomerSettlementEnum
    }
  })
}
  </script>
  
  <style lang="less" scoped>
.contenBox {
  margin-top: 10px;
  background: #f7f8fc;
  border-radius: 4px;
}

.title {
  color: #333;
  font-weight: 700;

  padding: 4px 10px;
}

.itemBox {
  padding: 2px 5px;
  background: #edf0f9;
  border-radius: 2px;
  font-size: 14px;
  color: #344563;
}

.tips {
  padding: 10px;
  border-top: 1px solid #fff;
  color: #999;
  font-size: 12px;
}
.all {
  color: #999;
  font-size: 12px;
  font-weight: normal;
}
</style>