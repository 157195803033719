<template>
  <div>
    <div v-for="item in subItemList" :key="item.id">
      <div class="contenBox" v-if="item.subItemList">
        <div class="title flex ju-between">
          <div>
            <span>{{ item.title }}</span>
            <span v-if="item.num" class="cur-p" @click="open(null,item)">
              <CountTo color="#0066CC" fontSize="14px" :endVal="item.num" />
            </span>
          </div>
          <div v-if="item.define">
            <a-tooltip>
              <template #title>
                <div v-html="item.define"></div>
              </template>
              <img class="tipsIcon" src="@/assets/images/newTransport/u60.png" alt="">
            </a-tooltip>
          </div>
        </div>
        <div v-if="item.subItemList" class="flex ju-between wrap" style="padding: 0px 10px 0px 10px">
          <div v-for="opt in item.subItemList" @click="open(opt,item)" :key="opt.id" class="itemBox m-b1 flex ju-between" style="width: 49%">
            {{ opt.title }}
            <a>{{ opt.num }}</a>
          </div>
        </div>
      </div>
      <div v-else class="info">
        {{ item.title }}：{{ item.num }}
      </div>
    </div>
    <template v-if="visible">
      <Table v-model:visible="visible" :title="title" @theBottom='theBottom' :x="x" @setRemaekOk="setRemaekOk" :colorIdx="colorIdx" :item="item" 
       @changeBtn="changeBtn" :loading="loading" :dataLength="data.length" @searchAdd="searchAdd" @resetBtn="resetBtn" keyType="pageType" :isAll="false"
       :totalClueNum="totalClueNum" :iptData="orderiptData1" :columns="ordercolumns1" :data="dataSource" :subItemList="subItemListData" 
       >
      </Table>
    </template>
  </div>
</template>
  
  <script setup>
import { onMounted, ref } from 'vue'
import CountTo from '@/components/countTo'
import Table from '../table'
import { orderiptData1, ordercolumns1} from './vehicleTracking'
import { documentaryExceptionList } from '@/api/businessCenter/businessCenter'
import { useStore } from 'vuex'
const store = useStore()
const props = defineProps({
  subItemList: {
    type: Array,
    default: []
  },
})
const visible = ref(false)
const title = ref('')
const x = ref(0)
const colorIdx = ref(0)
const loading = ref(false)
const data = ref([])
const dataSource = ref([])
const subItemListData = ref([])
const totalClueNum = ref(0)
const pageSize = ref(50)
const searchMsg = ref({})
const pageTypeNum = ref(null)

const resetBtn = () => {
  searchMsg.value = {}
  getData(pageTypeNum.value)
}
const searchAdd  = (e) => {
  searchMsg.value = e
  getData(pageTypeNum.value)
}
const changeBtn = (e) => {
  getData(e)
}
const open = (opt, item) => {
  searchMsg.value = {}
    subItemListData.value = item.subItemList
  if (!opt) {
    colorIdx.value = null
  } else {
    colorIdx.value = opt.pageType
  }
  getData(opt.pageType)
  title.value = item.title
  visible.value = true
}
 const getData = (pageType) => {
  dataSource.value = []
  pageSize.value = 50
  loading.value = true
  documentaryExceptionList({
    subType:pageType,
    ...searchMsg.value
  }).then( res => {
    if (res.code !== 10000) return
    pageTypeNum.value = pageType
    data.value = res.data
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
 }
 const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  orderiptData1.value.forEach( ipt => {
    if(ipt.prop === 'orgId'){
      ipt.opt = store.state.app.orgList
    }
  })
})
   </script>
  
  <style lang="less" scoped>
.contenBox {
  margin-top: 10px;
  background: #f7f8fc;
  border-radius: 4px;
}

.title {
  color: #333;
  font-weight: 700;
  font-size: 14px;
  padding: 4px 10px;
}

.itemBox {
  padding: 2px 5px;
  background: #edf0f9;
  border-radius: 2px;
  color: #344563;
}

.tips {
  padding: 10px;
  border-top: 1px solid #fff;
  color: #999;
  font-size: 12px;
}
.info {
  padding: 10px;
  padding-bottom: 0;
  color: #6b778c;
}
.tipsIcon {
  width: 15px;
  height: 15px;
}
</style>