<template>
  <div>
    <a-form ref="formRef" :model="formState" :rules="rules">
      <a-form-item label="请选择客户池" name="customerClass">
        <a-select style="width: 24%" v-model:value="formState.customerClass" placeholder="选择客户池">
          <a-select-option :value="2">私海客户</a-select-option>
          <a-select-option :value="3">合作客户</a-select-option>
          <a-select-option :value="-1">临时客户</a-select-option>
          <a-select-option :value="0">无效客户</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="请选择筛选条件(至少选择一个)">
        <div class="flex  wrap">
          <a-select style="width: 23% ;margin-right:10px" allowClear v-model:value="formState.intentionLevel" placeholder="意向程度">
            <a-select-option v-for="opt in $store.state.app.labelAll.INTENTION_LEVEL" :key="opt.value" :value="opt.value">{{ opt.label }}</a-select-option>
          </a-select>
          <a-select style="width: 23%;margin-right:10px" allowClear v-model:value="formState.customerType" placeholder="客户类型">
            <a-select-option v-for="opt in CustomerTypeEnum" :key="opt.value" :value="opt.value">{{ opt.label }}</a-select-option>
          </a-select>
          <a-select style="width: 23%;margin-right:10px" allowClear v-model:value="formState.coopType" placeholder="成交状态">
            <a-select-option v-for="opt in dealOpt" :key="opt.value" :value="opt.value">{{ opt.label }}</a-select-option>
          </a-select>
          <a-select style="width: 23%;" allowClear v-model:value="formState.vehicleGrade" placeholder="台量等级">
            <a-select-option v-for="opt in QuantityLevel" :key="opt.value" :value="opt.value">{{ opt.label }}</a-select-option>
          </a-select>
        </div>
      </a-form-item>
      <a-form-item label="">
        <div>
          <a-select style="width: 23%;" v-model:value="tagTypeValue" @change="tagTypeChange" placeholder="标签类型">
            <a-select-option v-for="opt in $store.state.app.labelAll.CUSTOMER_LABEL" :label="opt.label" :key="opt.value" :value="opt.value">{{ opt.label }}</a-select-option>
          </a-select>
          <a-button class="m-l1" @click="addTagType" type="primary">添加</a-button>
        </div>
        <div class="flex al-center wrap">
          <span class="m-t1">已选：</span>
          <div v-for="(opt,index) in tagTypeList" :key="opt.id" class="m-t1">
            <a-tag>
              {{ opt.label }}
              <a @click="closeTagType(opt,index)" style="color: #f00">X</a>
            </a-tag>
          </div>
        </div>
      </a-form-item>
      <a-form-item label="">
        <div>
          <a-input style="width: 23%" v-model:value="followValue" placeholder="跟进关键词">
          </a-input>
          <a-button class="m-l1" @click="addFollow" type="primary">添加</a-button>
        </div>
        <div class="flex al-center wrap">
          <span class="m-t1">已选：</span>
          <div v-for="(opt,index) in followList" :key="opt.id" class="m-t1">
            <a-tag>
              {{ opt }}
              <a @click="closeTagFollow(opt,index)" style="color: #f00">X</a>
            </a-tag>
          </div>
        </div>
      </a-form-item>
      <a-form-item label="分组命名" name="name">
        <a-input style="width: 24%" v-model:value="formState.name" placeholder="请输入">
        </a-input>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { groupAdd } from '@/api/businessCenter/businessCenter'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue';
import { IfNull } from '@/utils/util'
const emit = defineEmits(['success'])
const store = useStore()
const formRef = ref(null)
const CustomerTypeEnum = ref([])
const formState = reactive({
  customerClass: null,
  intentionLevel: null,
  tagType: null,
  customerType: null,
  follow: null,
  coopType: null,
  name: null,
  vehicleGrade: null,
  follows: [],
  tagTypes: []
});
const followValue = ref('')
const followList = ref([])
const tagTypeValue = ref(null)
const tagTypeLablel = ref(null)
const tagTypeList = ref([])
const dealOpt = [
  { label: '未成交', value: 1 },
  { label: '新客户', value: 2 },
  { label: '老客户', value: 3 },
]
const QuantityLevel = [
  { label: '一级客户', value: 1 },
  { label: '二级客户', value: 2 },
  { label: '三级客户', value: 3 },
  { label: '四级客户', value: 4 },
  { label: '五级客户', value: 5 },
]
const rules = {
  customerClass: [
    { required: true, message: '请选择客户池', trigger: 'blur', type: 'number' },
  ],
  name: [
    { required: true, message: '请输入分组名称', trigger: 'blur' },
  ]
};
onMounted(() => {
  CustomerTypeEnum.value = JSON.parse(JSON.stringify(store.state.enumAll.CustomerTypeEnum))
  if (CustomerTypeEnum.value[0].value === '') CustomerTypeEnum.value.splice(0, 1)
})
const closeTagType = (opt, index) => {
  tagTypeList.value.splice(index, 1)
}
const tagTypeChange = (e, v) => {
  tagTypeLablel.value = v
}
const addTagType = () => {
  if (tagTypeList.value.length === 5) {
    message.error('最多添加5个标签')
    return
  }
  let list = tagTypeList.value.map(item => item.value)
  if (list.indexOf(tagTypeValue.value) === -1) {
    tagTypeList.value.push(tagTypeLablel.value)
    tagTypeValue.value = null
  } else {
    message.error('已存在该标签')
  }
}
const closeTagFollow = (opt, index) => {
  followList.value.splice(index, 1)
}
const addFollow = () => {
  if (!IfNull(followValue.value)) return
  if (followList.value.length === 5) {
    message.error('最多添加5个关键词')
    return
  }
  if (followList.value.indexOf(followValue.value) === -1) {
    followList.value.push(followValue.value)
    followValue.value = ''
  } else {
    message.error('已存在该关键词')
  }
}
const getData = () => {
  formRef.value.validate().then(() => {
    if (!formState.intentionLevel && !formState.customerType 
      && !formState.coopType && !formState.vehicleGrade && followList.value.length === 0 && tagTypeList.value.length === 0) {
      message.error('至少选择一个')
      return
    }

    formState.follows = followList.value
    let list = tagTypeList.value.map(item => item.value)
    formState.tagTypes = list
    groupAdd(formState).then(res => {
      if (res.code !== 10000) return
      message.success('添加成功')
      emit('success')
    })
  })
}
defineExpose({ getData })
</script>

<style lang="less" scoped>
:deep(.ant-form-item-label) {
  width: 100%;
  text-align: left;
}
:deep(.ant-form-item) {
  margin-bottom: 5px;
}
</style>