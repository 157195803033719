<template>
<div>
  <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
  <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共<a>{{data.length}}</a>个 返款总金额(元)：&nbsp;<a>￥ {{ remakItem.settableAmt }} </a> 元</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="item => item.orderVehicleId" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #refunder="{ record }">
          {{ record.refunder }}&nbsp;{{ record.refunderMobile }}
        </template>
        <template #startAddress="{ record }">
          <div> {{ record.startAddress }}</div>
          {{ record.endAddress }}
        </template>
        <template #settlementStatus="{ record }">
          {{ record.orderSettlement?.label }}  {{ record.settlementStatus?.label }}
        </template>
        <template #add="{ record }">
         <a @click="lookDetails(record)"> 关注</a>
        </template>
      </a-table>
    </div>
</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import { orderRefundDetails } from '@/api/businessCenter/businessCenter'
import { getTableBody, IfNull} from '@/utils/util'
import { cloneDeep } from 'lodash-es';
const props = defineProps({
  customerId:{
    type: String,
    default: ''
  },
  refunder:{
    type: String,
    default: ''
  },
  remakItem:{
    type: Object,
    default: {}
  }
})
const tableRef = ref(null)
const dataSource = ref([])
const data = ref([])
const loading = ref(false)
const pageSize = ref(50)
const searchMsg = ref({})
const totalAmt = ref(0)

const searchAdd = (e) => {
  if (IfNull(e.orderId)) {
    dataSource.value = data.value.filter(item => {
      return item.orderId === IfNull(e.orderId)
    })
  }
}
const resetBtn = () => {
  dataSource.value = data.value.slice(0, pageSize.value)
 
}
const getOrderRefundDetails = () => {
  pageSize.value = 50
  loading.value = true
  orderRefundDetails({
    customerId:props.customerId,
    refunder:props.refunder, 
    ...searchMsg.value
  }).then( res => {
    if(res.code !== 10000 || !res.data) return
    data.value = res.data
    dataSource.value = res.data.slice(0,pageSize.value)
  }).finally(()=>{
    loading.value = false
  })
}
const theBottom = () => {
  if(dataSource.value.length === 0) return
  if(pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0,pageSize.value)
}
onMounted(()=>{
  getOrderRefundDetails()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'),theBottom )
})
const iptData = ref([
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '车牌/车型',
    dataIndex: 'vehicles',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'sourceMan',
    align: 'center',
  },
  {
    title: '分单员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus.label',
    align: 'center',
  },
  {
    title: '完成时间',
    dataIndex: 'orderFinishTime',
    align: 'center',
  },
  {
    title: '合同金额',
    dataIndex: 'contractAmt',
    align: 'center',
  },
  {
    title: '结算信息',
    dataIndex: 'orderSettlement',
    slots: {
      customRender: 'settlementStatus'
    },
    align: 'center',
  },
  {
    title: '返款金额',
    dataIndex: 'settableAmt',
    align: 'center',
  },
  {
    title: '返款信息',
    dataIndex: 'refunder',
    slots:{
      customRender: 'refunder'
    },
    align: 'center',
  },
])

</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>