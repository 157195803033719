<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共&nbsp;<a>{{ data.length }}</a>&nbsp;条数据</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #receiveContent="{ record }">
          <div class="flex al-center">
            <div style="width: 90%;">
              {{ record.receiveContent }}
            </div>
            <div>
             <div> <a @click="newAdd(record)">添加</a></div>
              <a v-if="record.receiveId" @click="recordLook(record)">记录</a>
            </div>
          </div>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="visible" title="回款描述" @cancel="value = null" @ok="submit">
      <a-textarea v-model:value="value" placeholder="请输入"></a-textarea>
    </a-modal>
    <a-modal title="回款描述记录" v-model:visible="recordLookVisible" footer="" @cancel="receiveData = []">
        <div class="recordLookBox">
          <div v-for="item in receiveData" :key="item.id" class="m-b1">
            <a-card >
              <div>添加人：<span>{{item.empName}}</span></div>
            <div>添加时间：<span>{{item.createTime}}</span></div>
            <div>添加内容：<span>{{ item.content }}</span></div>
           </a-card>
          </div>
        </div>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import moment from 'moment'
import { receivableTotal, planAdd ,receivePlanId} from '@/api/businessCenter/businessCenter'
import { transitionTimeYM, getTableBody, IfNull } from '@/utils/util'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
const store = useStore()

const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const visible = ref(false)
const value = ref(null)
const msg = ref({})
const searchMsg = ref({})
const recordLookVisible = ref(false)
const receiveData = ref([])

const recordLook = (record) => {
  recordLookVisible.value = true
  receivePlanId(record.receiveId).then(res => {
    if (res.code !== 10000) return
    receiveData.value = res.data
  })
}
const submit = () => {
  planAdd({
    content: value.value,
    customerId: msg.value.customerId,
    empId: msg.value.salesmanId
  }).then(res => {
    if (res.code !== 10000) return
    message.success('添加成功')
    visible.value = false
    dataSource.value.forEach(item => {
      if(item.customerId === msg.value.customerId && item.salesmanId === msg.value.salesmanId){
        item.receiveContent = value.value
      }
    })
    value.value = null
  })
}
const newAdd = (record) => {
  msg.value = record
  visible.value = true
}
const searchAdd = (e) => {
  pageSize.value = 50
searchMsg.value = e
getReceivableTotal()

}
const resetBtn = () => {
  pageSize.value = 50
  searchMsg.value = {}
  getReceivableTotal()
}
const getReceivableTotal = () => {
  loading.value = true
  receivableTotal({
    ...searchMsg.value
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data
    dataSource.value = res.data.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)

}
onMounted(() => {
  getReceivableTotal()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const iptData = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "select",
    placeholder: "结算方式",
    value: null,
    prop: "orderSettlement",
    key: 'value',
    keyVal: 'label',
    width: 200,
    opt: store.state.enumAll.orderSettlementList
  },
  {
    type: "select",
    placeholder: "是否添加回款记录",
    value: null,
    prop: "isAdd",
    key: 'value',
    keyVal: 'label',
    width: 200,
    opt: [
      { label: '是', value: 1 },
      { label: '否', value: 0 }
    ]
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },

  {
    title: '本月',
    dataIndex: 'nowMonthAmt',
    align: 'center',
  },
  {
    title: '上月',
    dataIndex: 'beforeMonthAmt',
    align: 'center',
  },
  {
    title: '上上月',
    dataIndex: 'lastMonthAmt',
    align: 'center',
  },
  {
    title: '逾期',
    dataIndex: 'overdueAmt',
    align: 'center',
  },
  {
    title: '合计',
    dataIndex: 'totalAmt',
    align: 'center',
  },
  {
    title: '回款描述',
    dataIndex: 'receiveContent',
    align: 'center',
    slots: {
      customRender: 'receiveContent'
    },
    width: 400
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
.recordLookBox{
  max-height: 600px;
  overflow: auto;
  span{
    color: #666;
  }
}
</style>