<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn">
    </SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共&nbsp;<a>{{ data.length }}</a>&nbsp;辆</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
        <template #vinNo="{ record }">
          {{ record.vinNo }}{{ record.brand }}{{ record.model }}
        </template>
        <template #transportType="{ record }">
          {{ record.transportType.label }}-{{ record.transStatus.label }}
        </template>
        <template #startAddress="{ record }">
          {{ record.startAddress }}
          <div>{{ record.endAddress }}</div>
        </template>
        <template #auditStatus="{ record }">
          <div>
          <span class="title-color" :style="{ color: record.auditStatus?.value === 1 ? '#0C8226' : '#D9001B' }">{{
            record.auditStatus?.label }}</span>
          <a-tooltip>
            <template #title>{{ record.auditRemark || '没有审核备注' }}</template>
            <MessageOutlined
              :style="{ color: record.auditStatus?.value === 1 ? '#0C8226' : '#D9001B', marginLeft: '5px' }" />
          </a-tooltip>
        </div>
        </template>
        <template #add="{ record }">
          <a v-if="record.auditStatus.value===0" @click="lookDetails(record)">审核</a>
        </template>
      </a-table>
    </div>
    <!-- 审核 -->
    <a-modal v-model:visible="reviewVisible" title="审核" :confirmLoading="btnLoading" @ok="FormListRef.getValRef()" destroyOnClose>
      <FormList @getVal="getVal" ref="FormListRef" :fromList="fromList"></FormList>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import moment from 'moment'
import { MessageOutlined } from '@ant-design/icons-vue'
import { transitionTimeYM, getTableBody, IfNull, addressTran } from '@/utils/util'
import { uninsuredAudit, uninsuredAuditList } from '@/api/businessCenter/businessCenter'
import FormList from '@/components/formList'
import { message } from 'ant-design-vue'
import { cloneDeep } from 'lodash-es'

const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const tableRef = ref(null)
const searchMsg = ref({
  auditStatus:0
})
const info = ref({})
const reviewVisible = ref(false)
const btnLoading = ref(false)
const FormListRef = ref(null)

//审核
const getVal = (e) => {
  if (e.auditStatus === 2) {
    if (!e.auditRemark) {
      message.error('请填写备注')
      return
    }
  }
  btnLoading.value = true
  uninsuredAudit({
    orderVehicleId: info.value.orderVehicleId,
    ...e
  }).then(res => {
    if (res.code !== 10000) return
    message.success('审核成功')
    reviewVisible.value = false
    dataSource.value.forEach( (item,index) => {
      if(item.orderVehicleId === info.value.orderVehicleId){
        data.value.splice(index,1)
        dataSource.value.splice(index,1)
      }
    })
  }).finally(() => {
    btnLoading.value = false
  })
}
const searchAdd = (e) => {
  searchMsg.value = cloneDeep(e) 
  searchMsg.value.startAddress = addressTran(searchMsg.value.startAddress)
  searchMsg.value.endAddress = addressTran(searchMsg.value.endAddress)
  getProfitList()
}
const resetBtn = () => {
  searchMsg.value = {
    auditStatus:0
  }
  getProfitList()
}
const lookDetails = (record) => {
  info.value = record
  reviewVisible.value = true
}
const getProfitList = () => {
  loading.value = true
  uninsuredAuditList({
    ...searchMsg.value,
  }).then(res => {
    if (res.code !== 10000) return
    data.value = res.data
    dataSource.value = data.value.slice(0, pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  getProfitList()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const fromList = ref([
  {
    label: "审核结果",
    name: "auditStatus",
    type: "select",
    placeholder: "请选择",
    value: null,
    prop: "auditStatus",
    width: "100%",
    opt: [
      {
        name: "通过",
        id: 1
      },
      {
        name: "不通过",
        id: 2
      }
    ],
    rules: [{ required: true, message: "请选择", trigger: "blur", type: "number" }],
  },
  {
    label: "备注",
    name: "auditRemark",
    type: "textarea",
    placeholder: "填写备注",
    value: null,
    prop: "auditRemark",
    width: "100%",
    rows: 3
  },
])
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    width: 200,
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    changeOn:true,
    width: 200,
  },
  {
    type: "select",
    placeholder: "审核状态",
    value: 0,
    prop: "auditStatus",
    width: 200,
    allowClear:false,
    opt:[{
      name:'待审核',
      id:0
    },{
      name:'审核通过',
      id:1
    },{
      name:'不通过',
      id:2
    }
    ]
  },
  {
    type: "input",
    placeholder: "报备人",
    value: null,
    prop: "reporter",
    width: 200,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots:{
      customRender: 'startAddress'
    },
    align: 'center',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '发运状态',
    dataIndex: 'transportType',
    slots: {
      customRender: 'transportType'
    },
    align: 'center',
  },
  {
    title: '报备原因',
    dataIndex: 'reportReason',
    align: 'center',
  },
  {
    title: '报备人',
    dataIndex: 'reporter',
    align: 'center',
  },
  {
    title: '报备时间',
    dataIndex: 'reportTime',
    align: 'center',
  },
  {
    title: '审核结果',
    dataIndex: 'auditStatus',
    slots: {
      customRender: 'auditStatus'
    },
    align: 'center',
  },
  {
    title: '审核人',
    dataIndex: 'auditor',
    align: 'center',
  },
  {
    title: '审核时间',
    dataIndex: 'auditTime',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    },
    width: 120
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>