import { ref } from 'vue'
//  1今日跟单 2待下载保单 3待下载合同 4待审核附加费 41 待审核附分析上报 5合同到期 6代打标签 7未投保检查 71百万投保

export const orderiptData1 = ref([
  {
    type: "selectTime",
    placeholder: "推送时间起",
    value: null,
    prop: "pushStartTime",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "推送时间止",
    value: null,
    prop: "pushEndTime",
    width: 200,
  },
  {
    type: "select",
    placeholder: "是否确认",
    value: null,
    prop: "isConfirm",
    width: 200,
    opt: [
      { name: '是', id: 1 }
      , { name: '否', id: 0 }
    ],
  },
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddressString",
    changeOn:true,
    fieldNamesValue: 'label',
    width: 200,
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddressString",
    changeOn:true,
    fieldNamesValue: 'label',
    width: 200,
  },
  {
    type: "select",
    placeholder: "结算方式",
    value: null,
    prop: "orderSettlement",
    width: 200,
    key: 'value',
    keyVal: 'label',
    opt: []
  },
  {
    type: "select",
    placeholder: "结算状态",
    value: null,
    prop: "settlementStatus",
    opt: [
      {
        name: "全部",
        id: ""
      },
      {
        name: "未结算",
        id: 0
      },
      {
        name: "部分结算",
        id: 1
      },
      {
        name: "已结算",
        id: 2
      },
    ],
    width: 200,
  },
  {
    type: "select",
    placeholder: "返款状态",
    value: null,
    prop: "isRefund",
    width: 200,
    opt: [
      { name: '是', id: 1 }
      , { name: '否', id: 0 }
    ],
  },
  {
    type: "select",
    placeholder: "返款结算",
    value: null,
    prop: "refundSettlementStatus",
    width: 200,
    opt: [
      { name: '已结算', id: 2 }
      , { name: '未结算', id: 0 }
    ],
  },
  {
    type: "select",
    placeholder: "订单状态",
    value: null,
    prop: "orderStatus",
    width: 200,
    key: 'value',
    keyVal: 'label',
    opt: [{
      value: 0,
      label: '保存'
    },
    {
      value: 3,
      label: '已接单'
    },
    {
      value: 4,
      label: '待验车'
    },
    {
      value: 5,
      label: '已验车'
    },
    {
      value: 8,
      label: '待提车'
    },
    {
      value: 11,
      label: '待推送'
    },
    {
      value: 1,
      label: '已推送'
    },
    {
      value: 2,
      label: '运输中'
    },

    {
      value: 6,
      label: '已拒绝'
    },
    {
      value: 7,
      label: '已取消'
    },
    {
      value: 9,
      label: '已完成'
    }]
  },
  {
    type: "select",
    placeholder: "小程序",
    value: null,
    prop: "isMiniOrder",
    width: 200,
    opt: [
      { name: '是', id: 1 }
      , { name: '否', id: 0 }
    ],
  },
  {
    type: "select",
    placeholder: "部门",
    value: null,
    prop: "orgId",
    width: 200,
    opt: []
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话",
    value: null,
    prop: "otherMobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "对接人",
    value: null,
    prop: "dockingPerson",
    width: 200,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员/分单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
export const orderiptData2 = ref([
  {
    type: "selectTime",
    placeholder: "推送时间起",
    value: null,
    prop: "pushTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "推送时间止",
    value: null,
    prop: "pushTimeEnd",
    width: 200,
  },

  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    changeOn:true,
    width: 200,
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    changeOn:true,
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
export const ordercolumns2 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
    slots: {
      customRender: 'customerName'
    },
  },
  {
    title: '电话/微信号',
    dataIndex: 'mobile',
    slots: {
      customRender: 'mobile'
    },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
  },
  {
    title: '订单车辆',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '确认运输时间',
    dataIndex: 'confirmTime',
    align: 'center',
    width: 80
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    align: 'center',
  },
  {
    title: '保单上传时间',
    dataIndex: 'insureUploadTime',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'Policy',
    slots: {
      customRender: 'Policy'
    },
    align: 'center',
    width: 80,
  },
])
export const orderiptData3 = ref([
  {
    type: "selectTime",
    placeholder: "推送时间起",
    value: null,
    prop: "pushTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "推送时间止",
    value: null,
    prop: "pushTimeEnd",
    width: 200,
  },

  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    changeOn:true,
    width: 200,
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    changeOn:true,
    prop: "endAddress",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
export const ordercolumns3 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
    slots: {
      customRender: 'customerName'
    },
  },
  {
    title: '电话/微信号',
    dataIndex: 'mobile',
    slots: {
      customRender: 'mobile'
    },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
  },
  {
    title: '订单车辆',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '确认运输时间',
    dataIndex: 'confirmTime',
    align: 'center',
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'contract',
    slots: {
      customRender: 'contract'
    },
    align: 'center',
    width: 80,
  },
])
export const orderiptData4 = ref([
  {
    type: "selectTime",
    placeholder: "上报时间起",
    value: null,
    prop: "explainTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "上报时间止",
    value: null,
    prop: "explainTimeEnd",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "carNo",
    width: 200,
  },
  {
    type: "input",
    placeholder: "上报人",
    value: null,
    prop: "explainer",
    width: 200,
  },
])
export const ordercolumns4 = ref([
  {
    title: '上报类型',
    dataIndex: 'reportType.label',
    align: 'center',
  },
  {
    title: '上报车辆',
    dataIndex: 'carNo',
    align: 'center',
  },
  {
    title: '上报人',
    dataIndex: 'explainer',
    align: 'center',
  },
  {
    title: '上报时间',
    dataIndex: 'explainTime',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '异常类型',
    dataIndex: 'exceptionType.label',
    align: 'center',
  },
  {
    title: '费用金额',
    dataIndex: 'amount',
    align: 'center',
  },
  {
    title: '异常照片',
    dataIndex: 'fileUrl',
    slots: {
      customRender: 'exceptionFileUrl'
    },
    align: 'center',
  },
  {
    title: '所属任务段',
    dataIndex: 'transportType.label',
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'exceptionExplain',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'surchargeAudit',
    slots: {
      customRender: 'surchargeAudit'
    },
    align: 'center',
    width: 80,
  },
])
export const orderiptData41 = ref([
  {
    type: "selectTime",
    placeholder: "上报时间起",
    value: null,
    prop: "reportTimeStart",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "上报时间止",
    value: null,
    prop: "reportTimeEnd",
    width: 200,
  },
  {
    type: "select",
    placeholder: "异常原因",
    value: null,
    prop: "exceptionType",
    width: 200,
    key: 'value',
    keyVal: 'label',
    opt:[]
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
  {
    type: "input",
    placeholder: "上报人",
    value: null,
    prop: "reporter",
    width: 200,
  },
])
export const ordercolumns41 = ref([
  {
    title: '上报车辆',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '上报人',
    dataIndex: 'reporter',
    align: 'center',
  },
  {
    title: '上报时间',
    dataIndex: 'reportTime',
    align: 'center',
  },
  {
    title: '异常原因',
    dataIndex: 'exceptionType.label',
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'abnormalAudit',
    slots: {
      customRender: 'abnormalAudit'
    },
    align: 'center',
    width: 80,
  },
])
export const orderiptData5 = ref([
  {
    type: "selectTime",
    placeholder: "合同时间起",
    value: null,
    prop: "startTime",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "合同时间止",
    value: null,
    prop: "endTime",
    width: 200,
  },
  {
    type: "select",
    placeholder: "结算方式",
    value: null,
    prop: "settlementMode",
    width: 200,
    key: 'value',
    keyVal: 'label',
    opt: []
  },
  {
    type: "select",
    placeholder: "部门",
    value: null,
    prop: "orgId",
    width: 200,
    opt: []
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "saleName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
])
export const ordercolumns5 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
    slots: {
      customRender: 'customerName'
    },
  },
  {
    title: '电话/微信',
    dataIndex: 'mobile',
    align: 'center',
    slots: {
      customRender: 'mobile'
    },
  },
  {
    title: '客户类型',
    dataIndex: 'customerType.label',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'saleName',
    align: 'center',
  },
  {
    title: '合作时间',
    dataIndex: 'enterCoopTime',
    align: 'center',
  },
  {
    title: '结算方式',
    dataIndex: 'settlementMode.label',
    align: 'center',
  },
  {
    title: '结算周期',
    dataIndex: 'settlementCycle',
    slots: {
      customRender: 'settlementCycle'
    },
    align: 'center',
  },
  {
    title: '合同名称',
    dataIndex: 'contractName',
    align: 'center',
  },
  {
    title: '签约信息',
    dataIndex: 'startTime',
    slots: {
      customRender: 'startTime'
    },
    align: 'center',
  },
  {
    title: '合同编号',
    dataIndex: 'code',
    align: 'center',
  },
  {
    title: '合同日期',
    dataIndex: 'startTime',
    slots: {
      customRender: 'startTimeHT'
    },
    align: 'center',
  },
  {
    title: '剩余天数',
    dataIndex: 'surplusDays',
    slots: {
      customRender: 'surplusDays'
    },
    align: 'center',
  },
  {
    title: '已到期',
    dataIndex: 'expireDays',
    slots: {
      customRender: 'expireDays'
    },
    align: 'center',
  },
])
export const orderiptData6 = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "select",
    placeholder: "意向等级",
    value: null,
    prop: "intentionLevel",
    width: 200,
    key: 'value',
    keyVal:'label',
    opt: []
  },
  {
    type: "selectMore",
    placeholder: "部门",
    value: [],
    prop: "orgIds",
    width: 200,
    opt:[]
  },
  {
    type: "selectSearch",
    placeholder: "业务员",
    value: null,
    prop: "saleId",
    width: 200,
    opt:[]
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "跟进信息",
    value: null,
    prop: "followContent",
    width: 200,
  },
])
export const ordercolumns6 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    slots: {
      customRender: 'customerName'
    },
    align: 'center',
  },
  {
    title: '客户类型',
    dataIndex: 'customerType.label',
    align: 'center',
  },
  {
    title: '线索跟进信息',
    dataIndex: 'follows',
    slots:{
      customRender:'follows'
    },
    width:200,
    align: 'center',
  },
  {
    title: '下次跟进时间',
    dataIndex: 'nextFollowTime',
    align: 'center',
  },
  {
    title: '意向等级',
    dataIndex: 'intentionLevel',
    slots: {
      customRender: 'intentionLevel'
    },
    align: 'center',
  },
  {
    title: '电话/微信号',
    dataIndex: 'mobile',
    slots: {
      customRender: 'mobile'
    },
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '领取时间',
    dataIndex: 'enterPrivateTime',
    align: 'center',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'saleName',
    align: 'center',
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    align: 'center',
  },
  {
    title: '客户来源',
    dataIndex: 'customerSourceType.label',
    align: 'center',
  },
  {
    title: '线索分类',
    dataIndex: 'clueType.label',
    align: 'center',
    width:80
  },
  {
    title: '起点-终点',
    dataIndex: 'clueLineStart',
    slots:{
      customRender:'clueLineStart'
    },
    align: 'center',
  },
])
export const ordercolumns61 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
    slots: {
      customRender: 'customerName'
    },
  },
  {
    title: '客户等级',
    dataIndex: 'level',
    slots: {
      customRender: 'level'
    },
    align: 'center',
  },
  {
    title: '客户状态',
    dataIndex: 'customerStatus',
    slots: {
      customRender: 'customerStatus'
    },
    align: 'center',
  },
  {
    title: '意向等级',
    dataIndex: 'intentionLevel',
    slots: {
      customRender: 'intentionLevel'
    },
    align: 'center',
  },
  {
    title: '客户来源',
    dataIndex: 'customerSourceType.label',
    align: 'center',
  },
  {
    title: '客户类型',
    dataIndex: 'customerType.label',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'saleName',
    align: 'center',
  },
  {
    title: '部门',
    dataIndex: 'orgName',
    align: 'center',
  },
  {
    title: '线索跟进信息',
    dataIndex: 'follows',
    slots:{
      customRender:'follows'
    },
    width:200,
    align: 'center',
  },
  {
    title: '下次联系时间',
    dataIndex: 'nextFollowTime',
    align: 'center',
  },
  {
    title: '联系电话',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '创建日期',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '领取日期',
    dataIndex: 'enterPrivateTime',
    align: 'center',
  },
  {
    title: '最新分配时间',
    dataIndex: 'updateTime',
    align: 'center',
  },
  {
    title: '最后下单时间',
    dataIndex: 'orderLastTime',
    align: 'center',
  },
])
export const orderiptData7 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    changeOn:true,
    width: 200,
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    changeOn:true,
    width: 200,
  },
  {
    type: "select",
    placeholder: "发运状态",
    value: null,
    prop: "transStatus",
    opt:[
        {
          name: '待安排',
          id: 1
        },
        {
          name: '待发运',
          id: 2
        },
        {
          name: '已发运',
          id: 3
        },
        {
          name: '中转代派',
          id: 4
        },
        {
          name: '已完成',
          id: 9
        },
      ],
    width: 200,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "sourceMan",
    width: 200,
  },
  {
    type: "input",
    placeholder: "录单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
export const ordercolumns7 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '电话/微信',
    dataIndex: 'mobile',
    align: 'center',
    slots: {
      customRender: 'mobile'
    },
  },
  {
    title: '业务员',
    dataIndex: 'sourceMan',
    align: 'center',
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    align: 'center',
  },
  {
    title: '发运状态',
    dataIndex: 'transStatus',
    slots: {
      customRender: 'transStatus'
    },
    align: 'center',
  },
  {
    title: '当前城市',
    dataIndex: 'nowLocation',
    slots: {
      customRender: 'nowLocation'
    },
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'Report',
    slots: {
      customRender: 'Report'
    },
    align: 'center',
    width: 200,
  },
])
export const ordercolumns71 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots: {
      customRender: 'startAddress'
    },
    align: 'center',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '电话/微信',
    dataIndex: 'mobile',
    align: 'center',
    slots: {
      customRender: 'mobile'
    },
  },
  {
    title: '业务员',
    dataIndex: 'sourceMan',
    align: 'center',
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '推送时间',
    dataIndex: 'pushTime',
    align: 'center',
  },
  {
    title: '发运状态',
    dataIndex: 'transStatus',
    slots: {
      customRender: 'transStatus'
    },
    align: 'center',
  },
  {
    title: '当前城市',
    dataIndex: 'nowLocation',
    slots: {
      customRender: 'nowLocation'
    },
    align: 'center',
  },
  {
    title: '投保信息',
    dataIndex: 'insuranceCompany',
    slots: {
      customRender: 'insuranceCompany'
    },
    align: 'center',
  },
])