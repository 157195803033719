import { ref } from 'vue'

//  1客户 2订单 3车辆 4财务全部 5 财务正常
export const orderiptData1 = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "select",
    placeholder: "意向等级",
    value: null,
    prop: "intentionLevel",
    width: 200,
    key: 'value',
    keyVal:'label',
    opt: []
  },
  {
    type: "selectSearch",
    placeholder: "业务员",
    value: null,
    opt:[],
    prop: "saleId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "wechat",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "mobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "跟进信息",
    value: null,
    prop: "followContent",
    width: 200,
  },
])
export const ordercolumns1 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '客户名称',
    dataIndex: 'name',
    slots: {
      customRender: 'name'
    },
    align: 'center',
  },
  {
    title: '电话/微信号',
    dataIndex: 'mobile',
    slots:{
      customRender: 'mobile'
    },
    align: 'center',
  },
  {
    title: '客户类型',
    dataIndex: 'customerType.label',
    align: 'center',
  },
  {
    title: '意向等级',
    dataIndex: 'intentionLevel.label',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'saleName',
    align: 'center',
  },
  {
    title: '所在位置',
    dataIndex: 'customerClass.label',
    align: 'center',
  },
  {
    title: '领取时间',
    dataIndex: 'enterPrivateTime',
    align: 'center',
  },
  {
    title: '合作时间',
    dataIndex: 'enterCoopTime',
    align: 'center',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    align: 'center',
  },
  {
    title: '下次跟进时间',
    dataIndex: 'nextFollowTime',
    align: 'center',
  },
  {
    title: '上次下单时间',
    dataIndex: 'orderLastTime',
    align: 'center',
  },
  {
    title: '线索跟进信息',
    dataIndex: 'follows',
    align: 'center',
  },
  {
    title: '关注',
    dataIndex: 'cancelFollow',
    slots:{
      customRender:'cancelFollow'
    },
    align: 'center',
    width: 80,
  },
])
export const orderiptData2 = ref([
  {
    type: "selectTime",
    placeholder: "推送时间起",
    value: null,
    prop: "pushStartTime",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "推送时间止",
    value: null,
    prop: "pushEndTime",
    width: 200,
  },
  {
    type: "select",
    placeholder: "是否确认",
    value: null,
    prop: "isConfirm",
    width: 200,
    opt:[
      {name:'是',id:1}
      ,{name:'否',id:0}
    ],
  },
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startCitys",
    fieldNamesValue:'label',
        width: 200,
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endCitys",
    fieldNamesValue:'label',
    width: 200,
  },
  {
    type: "select",
    placeholder: "结算方式",
    value: null,
    prop: "orderSettlement",
    width: 200,
    key: 'value',
    keyVal:'label',
    opt:[]
  },
  {
    type: "select",
    placeholder: "结算状态",
    value: null,
    prop: "settlementStatus",
    opt:[
     {
      name: "全部",
      id: ""
     },
     {
      name: "未结算",
      id: 0
     },
     {
      name: "部分结算",
      id: 1
     },
     {
      name: "已结算",
      id: 2
     },
    ],
    width: 200,
  },
  {
    type: "select",
    placeholder: "返款状态",
    value: null,
    prop: "isRefund",
    width: 200,
    opt:[
      {name:'是',id:1}
      ,{name:'否',id:0}
    ],
  },
  {
    type: "select",
    placeholder: "返款结算",
    value: null,
    prop: "refundSettlementStatus",
    width: 200,
    opt:[
      {name:'已结算',id:2}
      ,{name:'未结算',id:0}
    ],
  },
  {
    type: "select",
    placeholder: "订单状态",
    value: null,
    prop: "orderStatus",
    width: 200,
    key: 'value',
    keyVal:'label',
    opt:    [{
      value: 0,
      label: '保存'
    },
    {
      value: 3,
      label: '已接单'
    },
    {
      value: 4,
      label: '待验车'
    },
    {
      value: 5,
      label: '已验车'
    },
    {
      value: 8,
      label: '待提车'
    },
    {
      value: 11,
      label: '待推送'
    },
    {
      value: 1,
      label: '已推送'
    },
    {
      value: 2,
      label: '运输中'
    },

    {
      value: 6,
      label: '已拒绝'
    },
    {
      value: 7,
      label: '已取消'
    },
    {
      value: 9,
      label: '已完成'
    }]
  },
  {
    type: "select",
    placeholder: "小程序",
    value: null,
    prop: "isMiniOrder",
    width: 200,
    opt:[
      {name:'已结算',id:2}
      ,{name:'未结算',id:0}
    ],
  },
  {
    type: "select",
    placeholder: "部门",
    value: null,
    prop: "orgId",
    width: 200,
    opt: []
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话",
    value: null,
    prop: "otherMobile",
    width: 200,
  },
  {
    type: "input",
    placeholder: "对接人",
    value: null,
    prop: "dockingPerson",
    width: 200,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员/分单员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
export const orderiptData3 = ref([
  {
    type: "selectTime",
    placeholder: "起运地",
    value: null,
    prop: "startTime",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "目的地",
    value: null,
    prop: "endTime",
    width: 200,
  },
  {
    type: "cascader",
    placeholder: "发运状态",
    value: null,
    prop: "channelSubId",
    width: 200,
    opt:[],
    changeOn:true
  },
  {
    type: "select",
    placeholder: "订单号",
    value: null,
    prop: "clueType",
    width: 200,
    key: 'value',
    keyVal:'label',
    opt: []
  },
  {
    type: "select",
    placeholder: "业务员",
    value: null,
    prop: "clueType",
    width: 200,
    key: 'value',
    keyVal:'label',
    opt: []
  },
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "isFall",
    width: 200,
  },
  {
    type: "input",
    placeholder: "微信号",
    value: null,
    prop: "isFall",
    width: 200,
  },
  {
    type: "input",
    placeholder: "电话号码",
    value: null,
    prop: "isFall",
    width: 200,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "isFall",
    width: 200,
  },
])
export const ordercolumns3 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 50,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '车牌/车型',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'wechat',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'clueCreateTime',
    align: 'center',
  },
  {
    title: '客户名称',
    dataIndex: 'overTime',
    slots: {
      customRender: 'overTime'
    },
    align: 'center',
  },
  {
    title: '电话/微信',
    dataIndex: 'startAddress',
    align: 'center',
    slots: {
      customRender: 'startAddress'
    },
  },
  {
    title: '业务员',
    dataIndex: 'channelSubName',
    align: 'center',
  },
  {
    title: '录单员',
    dataIndex: 'isRepetitive.label',
    align: 'center',
    width:80
  },
  {
    title: '推送时间',
    dataIndex: 'remark',
    slots:{
      customRender: 'remark'
    },
    align: 'center',
  },
  {
    title: '调度员',
    dataIndex: 'add',
    slots:{
      customRender:'add'
    },
    align: 'center',
  },
  {
    title: '发车时间',
    dataIndex: 'add',
    align: 'center',
  },
  {
    title: '发运状态',
    dataIndex: 'add',
    align: 'center',
  },
  {
    title: '所在大板',
    dataIndex: 'add',
    align: 'center',
  },
  {
    title: '当前城市',
    dataIndex: 'add',
    align: 'center',
  },
  {
    title: '跟单信息',
    dataIndex: 'add',
    align: 'center',
  },
  {
    title: '关注',
    dataIndex: 'distribution',
    slots:{
      customRender:'distribution'
    },
    align: 'center',
    width: 80,
  },
])
export const orderiptData4 = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "selectSearch",
    placeholder: "业务员",
    value: null,
    prop: "saleId",
    width: 200,
    opt: []
  },
  {
    type: "select",
    placeholder: "结算方式",
    value: null,
    prop: "orderSettlement",
    key: 'value',
    keyVal:'label',
    opt:[],
    width: 200,
  },
])
export const ordercolumns4 = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    width: 70,
    align: 'center',
    slots: {
      customRender: 'sort'
    },
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '小车信息',
    dataIndex: 'vehicles',
    align: 'center',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'startAddress',
    slots:{
      customRender: 'startAddress'
    },
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'sourceMan',
    align: 'center',
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '确认时间',
    dataIndex: 'orderTime',
    align: 'center',
  },
  {
    title: '完成时间',
    dataIndex: 'orderFinishTime',
    align: 'center',
  },
  {
    title: '结算信息',
    dataIndex: 'orderSettlement.label',
    align: 'center',
  },
  {
    title: '合同金额',
    dataIndex: 'contractAmt',
    align: 'center',
    width:100
  },
  {
    title: '已结算金额',
    dataIndex: 'settledAmt',
    align: 'center',
    width:100
  },
  {
    title: '未结算金额',
    dataIndex: 'settableAmt',
    align: 'center',
    slots:{
      customRender:'settableAmt'
    },
    width:100
  },
  {
    title: '逾期天数',
    dataIndex: 'overdueDay',
    align: 'center',
    slots:{
      customRender:'overdueDay'
    },
    width:80
  },
])


