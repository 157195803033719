<template>
  <div>
    <div v-if="styleType == 1">
      <KeyIssues  :modal="modal" :subItemList="msg.subItemList" :msg="msg" />
    </div>
    <div v-if="styleType == 3">
      <CustomerPool :modal="modal" :subItemList="msg.subItemList" />
    </div>
    <div v-if="styleType == 4 || styleType == 5 ">
      <Order :subItemList="msg.subItemList" :styleType="styleType" />
    </div>
    <div v-if="styleType == 7">
      <FunctionalArea :item="msg" :vehicleNumType="vehicleNumType" />
    </div>
    <div v-if="styleType == 14 || styleType == 13">
      <MyAttention :subItemList="msg.subItemList" />
    </div>
   
    <div  v-if="styleType == 8">
      <VehicleTracking :subItemList="msg.subItemList" />
    </div>
    <div v-if="styleType == 9">
      <CustomerInquiry />
    </div>
    <div v-if="styleType == 10">
      <CheckCar />
    </div>
    <div  v-if="styleType == 11">
      <InvoiceInquiry />
    </div>
  </div>
</template>

<script setup>
import KeyIssues from './keyIssues'
import MyAttention from './myAttention'
import Order from './order'
import FunctionalArea from './FunctionalArea'
import CustomerPool from './customerPool';
import CustomerInquiry from './customerInquiry';
import CheckCar from './checkCar'
import VehicleTracking from './vehicleTracking'
import InvoiceInquiry from './invoiceInquiry'


const props = defineProps({
  styleType: {
    type: [String, Number],
    default: null
  },
  msg: {
    type: Object,
    default: {}
  },
  modal:{

  },
})
</script>

  <style lang="less" scoped>
</style>
