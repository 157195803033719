import { ref } from 'vue'

//  1c车辆跟单
export const orderiptData1 = ref([
  {
    type: "selectTime",
    placeholder: "跟单时间起",
    value: null,
    prop: "startTime",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "跟单时间止",
    value: null,
    prop: "endTime",
    width: 200,
  },
  {
    type: "select",
    placeholder: "部门",
    value: null,
    prop: "orgId",
    width: 200,
    opt: [],
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 200,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "salesman",
    width: 200,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 200,
  },
])
export const ordercolumns1 = ref([
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    slots: {
      customRender: 'vinNo'
    },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    align: 'center',
  },
  {
    title: '所属部门',
    dataIndex: 'orgName',
    align: 'center',
  },
  {
    title: '跟单详情',
    dataIndex: 'documentaryDetails',
    slots: {
      customRender: 'documentaryDetails'
    },
    width:500
  },
  {
    title: '跟单时间',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '异常状态',
    dataIndex: 'documentaryStatus.label',
    align: 'center',
  },
  {
    title: '发运状态',
    dataIndex: 'transStatus',
    slots: {
      customRender: 'transStatus'
    },
    align: 'center',
  },
  {
    title: '起运地',
    dataIndex: 'startAddress',
    align: 'center',
  },
  {
    title: '目的地',
    dataIndex: 'endAddress',
    align: 'center',
  },
  {
    title: '跟单记录',
    dataIndex: 'operate',
    slots: {
      customRender: 'operate'
    },
    align: 'center',
    width: 80,
  },
])

