<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchChange="searchChange" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">当前筛选出&nbsp;<a>{{ data.length }}</a>&nbsp;条</div>
      <a-table ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted ,reactive} from 'vue'
import SearchList from '@/components/searchList'
import moment from 'moment'
import { salesmanCustomerVehicle } from '@/api/businessCenter/businessCenter'
import { transitionTimeYM, debounce, IfNull, getTableBody } from '@/utils/util'
import { useStore } from 'vuex'
import { employeeList } from '@/api/crmManagement/setRules'
import { cloneDeep } from 'lodash-es'
const store = useStore()

const tableRef = ref(null)
const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const searchMsg = ref({})
const state= reactive({
  timer:null
})

const resetBtn = () => {
  searchMsg.value = {}
  pageSize.value = 50
  getSalesmanCustomerVehicle()
}
const searchAdd = (val) => {
  let msg = cloneDeep(val)
  searchMsg.value = msg
  pageSize.value = 50
  getSalesmanCustomerVehicle()
}
const searchChange = (e) => {
  if(!IfNull(e)) return
  debounce(state,getEmployeeList,1000,e)
}
const getSalesmanCustomerVehicle = () => {
  loading.value = true
  salesmanCustomerVehicle({
    ...searchMsg.value,
  }).then( res => {
    if(res.code !== 10000) return
    data.value = res.data
    dataSource.value = data.value.slice(0,pageSize.value)
  }).finally(() => {
    loading.value = false
  })
}

const getEmployeeList = (e) => {
  employeeList({
    name: e,
  }).then(res => {
    if (res.code === 10000) {
      let list = []
      list = res.data.map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
      iptData.value[1].opt = list
    }
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  getSalesmanCustomerVehicle()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const iptData = ref([
  {
    type: "input",
    placeholder: "客户名称",
    value: null,
    prop: "customerName",
    width: 200,
  },
  {
    type: "selectSearch",
    placeholder: "业务员",
    value: null,
    prop: "saleId",
    opt:[],
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 80
  },
  {
    title: '业务员',
    dataIndex: 'saleName',
    align: 'center',
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '本月台量',
    dataIndex: 'nowMonthNum',
    align: 'center',
  },
  {
    title: '上月台量',
    dataIndex: 'beforeMonthNum',
    align: 'center',
  },
  {
    title: '上上月台量',
    dataIndex: 'lastMonthNum',
    align: 'center',
  },
  {
    title: '本年度台量合计',
    dataIndex: 'nowYearNum',
    align: 'center',
  },
  {
    title: '去年台量',
    dataIndex: 'beforeYearNum',
    align: 'center',
  },
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>