customerType<template>
  <div>
    <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
      <a-form-item>
        <template #label>
          <div><span style="color: #f00">* </span>客户类型</div>
        </template>
        <a-select v-model:value="addForm.customerType.value" @change="customerTypeChange" :options="CustomerTypeEnum" placeholder="请选择客户类型">
        </a-select>
      </a-form-item>

      <a-form-item v-if="addForm.customerType.value === 2" label="企业名称" name="name" :rules="{
        required: true,
        message: '请填写企业名称',
        trigger: 'change',
        type: 'string',
        }">
        <a-select v-model:value="addForm.name" placeholder="请输入企业名称" :filter-option="false" :show-arrow="false" @search="entpSearch" @select="entpSelect" show-search>
          <a-select-option v-for="item in enterprises" :key="item.creditCode" :value="item.name">
            <span>{{ item.name }}</span>
            <span style="float: right">{{ item.operName }}</span>
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="客户姓名" name="name" v-if="addForm.customerType.value === 1">
        <a-input v-model:value="addForm.name" placeholder="请填写客户姓名" />
      </a-form-item>

      <a-form-item label="客户来源" name="customerSourceType">
        <div style="display: flex;">
          <a-select allowClear v-model:value="addForm.customerSourceType" :options="$store.state.enumAll.customerSource" placeholder="选择客户来源" @change="customerSourceTypeChange">
          </a-select>
          <a-select v-if="addForm.customerSourceType === 2" :filter-option="false" show-search placeholder="请输入员工姓名" @search="employerValue">

            <template v-if="state.flagLoading" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option v-for="item in state.employeeOptions" :key="item.id" :value="item.name" @click="addEmployeeChoose(item)">{{ item.name }}-{{ item.orgName }}</a-select-option>
          </a-select>
          <a-select v-if="addForm.customerSourceType === 3" placeholder="搜索并选择客户" @search="addEntpSearch" labelInValue :filterOption="false" show-search>
            <template v-if="state.fetching" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option v-for="item in enterprises" :key="item.name" :value="item.id" @click="enterpriseChoose(item)">
              {{ item.name }}—{{ item.mobile }}
            </a-select-option>
          </a-select>
          <a-cascader v-if="addForm.customerSourceType === 4" v-model:value="addForm.addChannels" :options="$store.state.enumAll.ChannelTypeEnum" :show-search="{ filter }" :changeOnSelect="true" placeholder="选择渠道" />
        </div>
      </a-form-item>

      <a-form-item label="客户电话" name="mobile">
        <a-input v-model:value="addForm.mobile" placeholder="请填写客户电话" />
      </a-form-item>

      <a-form-item label="客户微信">
        <a-input v-model:value="addForm.wechat" placeholder="填写客户微信" />
      </a-form-item>

      <a-form-item label="客户地址">
        <a-input v-model:value="addForm.address" placeholder="请填写客户地址" />
      </a-form-item>

      <a-form-item name="certificateNumber">
        <template #label>
          {{ addForm.customerType.value === 2 ? "企业信用代码" : "客户身份证" }}
        </template>
        <a-input v-model:value="addForm.certificateNumber" placeholder="请填写" />
      </a-form-item>
      <a-form-item label="线索类型">
        <a-select allowClear v-model:value="addForm.clueType" :options="$store.state.enumAll.ClueTypeEnum" placeholder="请选择线索类型">
          <a-select-option v-for="item in $store.state.enumAll.ClueTypeEnum" :key="item.value">
            {{ item.label }}</a-select-option>
        </a-select>
      </a-form-item>

    </a-form>
  </div>
</template>

<script setup>
import { cloneDeep } from 'lodash-es';
import { ref, reactive, onMounted } from 'vue'
import { enterprise } from '@/api/system/setting'
import { employeeList } from '@/api/crmManagement/setRules'
import { pageAll } from '@/api/crm/customer'
import { useStore } from 'vuex'
import { add } from '@/api/crmManagement/privateSea'
import {
  enterpriseValidate,
  idNumberValidate,
  positiveNumberValidate
} from '@/utils/validate'
import { message } from 'ant-design-vue';
const store = useStore()
const formRef = ref(null)
const CustomerTypeEnum = ref([])
const timeOut = ref(null)
const enterprises = ref([])
const emit = defineEmits(['success'])

const state = reactive({
  flagLoading: false,
  fetching: false,
  timer: null,
  employeeOptions:[]
})
const addForm = reactive({
  name: '',
  mobile: '',
  wechat: '',
  customerSourceType: null,
  sourcePersonId: '',
  sourcePerson: '',
  customerType: { value: 1, label: '个人' }
},)
const rules = reactive({
  name: { required: true, message: '请填写客户名称', trigger: 'blur' },
  customerType: {
    required: true,
    message: '请选择客户类型',
    trigger: 'change',
    type: 'number'
  },
  customerSourceType: {
    required: true,
    message: '请选择客户来源',
    trigger: 'blur',
    type: 'number'
  },
  settlementMode: {
    required: true,
    message: '请选择结算方式',
    trigger: 'change',
    type: 'number'
  },
  certificateNumber: [
    { required: false, message: '请填写身份证号码', trigger: 'blur' },
    {
      pattern: idNumberValidate,
      message: '请填写正确的身份证号码',
      trigger: 'blur'
    }
  ],
  mobile: [
    { required: false, message: '请填写电话号码', trigger: 'blur' },
    {
      pattern: positiveNumberValidate,
      message: '请填写正确的电话号码',
      trigger: 'blur'
    }
  ]
},)
// 客户类型选择
const customerTypeChange = (e, v) => {
  addForm.customerType = v
  if (addForm.customerType.value === 2) {
    rules.name.message = '请填写企业名称'
    rules.certificateNumber[1].pattern = enterpriseValidate
    rules.certificateNumber[1].message = '请输入正确的企业信用代码'
  } else {
    rules.name.message = '请填写客户姓名'
    rules.certificateNumber[1].pattern = idNumberValidate
    rules.certificateNumber[1].message = '请输入正确的身份证号'
  }
}
const addEmployeeChoose = item => {
     addForm.sourcePerson = item.name
      addForm.sourcePersonId = item.id
    }
const customerSourceTypeChange = () => {
  addForm.sourcePerson = null
  addForm.sourcePersonId = null
  addForm.addChannels = []
  addForm.channelId = ''
  addForm.channelSubId = ''
}
const entpSelect = async (val, option) => {
  addForm.name = val
  addForm.certificateNumber = option.key
}
// 新增-企业客户列表获取
const entpSearch = async (val) => {
  if (val.length > 3) {
    const filterVal = val
      .replace(new RegExp('[a-z]', 'ig'), '')
      .replace(new RegExp('[0-9]', 'g'), '')
    if (filterVal.length < 4) {
      return
    }
    if (timeOut.value !== null) {
      clearTimeout(timeOut.value)
    }
    timeOut.value = setTimeout(() => {
      enterprise({ name: val }).then((res) => {
        if (res.code === 10000) {
          enterprises.value = res.data
          timeOut.value = null
        } else {
          enterprises.value = []
        }
      })
    }, 500)
  } else {
    enterprises.value = []
  }
}
 // 新增
 const onAddForm = () => {
      if (addForm.mobile !== '' || addForm.wechat !== '') {
        formRef.value
          .validate()
          .then(() => {
            const channelArr = addForm.addChannels
            if (
              channelArr !== undefined &&
              channelArr !== null &&
              channelArr.length > 1
            ) {
              addForm.channelId = channelArr[0]
              addForm.channelSubId = channelArr[1]
            }
            delete addForm.addChannels
            add({ customer: addForm }).then((res) => {
              if (res.code === 10000) {
                message.success(res.msg)
               emit('success')
              }
            })
          }).catch((err) => {
            console.log(err)
          })
      } else message.error('微信号和电话必须填写其中一个')
    }
// 员工搜索
const employerValue = (e) => {
  if (e === '' || e === null) clearTimeout(state.timer), (state.fetching = false)
  state.flagLoading = true
  if (state.timer !== null) {
    clearTimeout(state.timer)
  }
  state.timer = setTimeout(() => {
    employeeList({
      current: 1,
      name: e,
      size: 10
    })
      .then((res) => {
        if (res.code === 10000) {
          state.employeeOptions = res.data
          state.flagLoading = false
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, 1000)
}
const addEntpSearch = val => {
      if (val.length > 1) {
        if (timeOut.value !== null) {
          clearTimeout(timeOut.value)
        }
        timeOut.value = setTimeout(() => {
          pageAll({ name: val }).then((res) => {
            if (res.code === 10000) {
              enterprises.value = res.data.records
              timeOut.value = null
            } else {
              enterprises.value = []
            }
          })
        }, 500)
      } else {
        enterprises.value = []
      }
    }
    const enterpriseChoose = item => {
      addForm.introduceCustomerName = item.name
      addForm.introduceCustomerId = item.id
    }
onMounted(() => {
  CustomerTypeEnum.value = cloneDeep(store.state.enumAll.CustomerTypeEnum)
  if (CustomerTypeEnum.value[0].value === '') CustomerTypeEnum.value.splice(0, 1)
})
defineExpose({
  onAddForm
})
</script>

<style lang="less" scoped>
</style>