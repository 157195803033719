<template>
  <div>
    <div class="m-t1 tableBox">
    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" bordered size="small" :pagination="false" :loading="loading" >
      <template #purchasingUnit="{record}">
          {{ record.purchasingUnit }}
          <div v-if="record.invoiceTypeName">【{{ record.invoiceTypeName }}】</div>
        </template>
        <template #invoiceCarrier="{ record }">
          <div>
            <div>{{ record?.invoiceCarrier }}</div>
            <div><span v-if="record.taxRate">{{ record.taxRate }}% - </span>{{ record.taxItem }}</div>
          </div>
        </template>
        <template #invoiceType="{ record }">
          {{ record.invoiceType?.label }}
          <div>{{  record.invoiceMethod?.label  }}</div>
        </template>
        <template #ticketCategory="{ record }">
          <div>{{  record.ticketCategory?.label }}</div>
          {{  record.invoiceMethod?.label }}
        </template>
        <template #applyInvoiceType="{ record }">
          {{ record.applyInvoiceType?.label }} <div v-if="record.cancelResult?.value > 2 "><a-tag color="red">{{record.cancelResult?.label}}</a-tag></div>

        </template>
        <template #invoiceResult="{ record }">
          {{  record.invoiceResult?.label}}
        </template>
       <template #add="{ record }">
        <a @click="lookAdd(record)">查看</a>
       </template>
    </a-table>
    </div>
      <LookInitiate  :onlyDisplay="true" :invoiceCarrierList="state.invoiceCarrier.opt" v-if="lookVisible" :lookID="lookID" v-model:visible="lookVisible"></LookInitiate>
      <LookEnd  v-if="lookEndVisible" :lookID="lookID" :lookItem="lookItem" v-model:visible="lookEndVisible"></LookEnd>
  </div>
</template>

<script setup>
import { onMounted, ref,reactive } from 'vue'
import { orderInvoiceSearch } from '@/api/businessCenter/businessCenter'
import { useRouter } from 'vue-router'
import LookInitiate from '@/views/finance/InitiateBilling/lookInitiate/index.vue'
import LookEnd from '@/views/finance/InitiateBilling/lookInitiate/lookEnd.vue'
import { getSubjectList } from '@/api/trans/order'
const router = useRouter()
const props = defineProps({
  typeNum:{
    type: Number,
    default: 0
  },
  keyword:{
    type: String,
    default: ''
  }
})
const loading = ref(false)
const listData = ref([])
// 查看
const lookItem = ref({})
const lookID = ref('')
const lookVisible = ref(false)
const lookEndVisible = ref(false)
const state = reactive({
  invoiceCarrier: {
    value: null,
    width: 180,
    placeholder: '开票主体',
    opt: []
  },
  taxItem: {
    value: null,
    width: 180,
    placeholder: '开票税率',
    opt: []
  },
})

const lookAdd = (item) => {
  lookID.value = item.id
  if (item.invoiceResult.value == 1) {
    lookVisible.value = true
  } else {
    lookItem.value = item
    lookEndVisible.value = true
  }
}


const loadData = () => {
  loading.value = true
  orderInvoiceSearch({
    vinNo: props.typeNum === 0 ? props.keyword : '',
    orderId: props.typeNum === 1 ? props.keyword : '',
  }).then(res => {
    // console.log(res)  // 主要动态数据
    if (res.code === 10000) {
      listData.value = res.data
    }
  }).finally(() => {
    loading.value = false
  })
}
const columns = ref([
  {
    title: '发票号',
    dataIndex: 'invoiceNum',
    key: 'invoiceNum',
    align: 'center',
    width: '120px'
  },
  {
    title: '开票单号',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: '120px'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'center',
    width: '150px'
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center',
  },
  {
    title: '购方单位',
    dataIndex: 'purchasingUnit',
    key: 'purchasingUnit',
    align: 'center',
    width: '200px',
    slots: { customRender: 'purchasingUnit' }
  },
  {
    title: '金额',
    dataIndex: 'amt',
    key: 'amt',
    align: 'center',
    width: '80px'
  },
  {
    title: '开票人',
    dataIndex: 'sponsor',
    key: 'sponsor',
    align: 'center',
    width: '80px'
  },
  {
    title: '开票主体',
    dataIndex: 'invoiceCarrier',
    key: 'invoiceCarrier',
    align: 'center',
    width: '220px',
    slots: { customRender: 'invoiceCarrier' }
  },
  {
    title: '票类',
    dataIndex: 'ticketCategory',
    key: 'ticketCategory',
    align: 'center',
    slots: { customRender: 'ticketCategory' }
  },
  {
    title: '开票类型',
    dataIndex: 'applyInvoiceType',
    key: 'applyInvoiceType',
    align: 'center',
    slots: { customRender: 'applyInvoiceType' }

  },
  {
    title: '申请结果',
    dataIndex: 'invoiceResult',
    key: 'invoiceResult',
    slots: { customRender: 'invoiceResult' },
    align: 'center',
    width: '100px'
  },
  {
    title: '流程编号',
    dataIndex: 'flowInstanceId',
    key: 'flowInstanceId',
    align: 'center',
    width: '120px'
  },

  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: '100px',
    slots: { customRender: 'add' }
  }])
onMounted(() => {
  loadData()
  reqGetSubjectList(0)
})
// 获取主体列表
const reqGetSubjectList = (id, name) => {
  getSubjectList(id).then(res => {
    if (res.code !== 10000) return
    if (id == 0) {
      state.invoiceCarrier.opt = res.data
    } else {
      res.data.forEach(item => {
        item.label = item.subjectName + `【${item.taxItem}】`
      })
      state.taxItem.opt = res.data
    }
  })
}
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>