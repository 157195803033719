<template>
  <div>
    <SearchList :iptData="iptData" :loading="loading" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #customFooter>
          <a-select  allowClear v-model:value="empName" style="width:200px" @change="selectChange" placeholder="业务员">
            <a-select-option v-for="opt in empNameOpt" :key="opt.id" :value="opt.id">{{ opt.name }}</a-select-option>
          </a-select>
      </template>
    </SearchList>
    <div class="m-t1 tableBox">
      <div class="sys-screenTitle">共&nbsp;<a>{{ data.length }}</a>&nbsp;条数据</div>
      <a-table :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" ref="tableRef" :dataSource="dataSource" :rowKey="(item,index) => index" size="small" :columns="columns" bordered :pagination="false" :loading="loading" :scroll="{y:500}">
        <template #sort="{ index }">
          {{ index+1 }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
import moment from 'moment'
import { transitionTimeYM,getTableBody , IfNull} from '@/utils/util'
import { salesmanWorks } from '@/api/businessCenter/businessCenter'
import { cloneDeep } from 'lodash-es'

const loading = ref(false)
const dataSource = ref([])
const data = ref([])
const pageSize = ref(50)
const searchMsg = ref({})
const tableRef = ref(null)
const empName = ref(null)
const empNameOpt = ref([])

const selectChange = (e) => {
  if(!e){
    pageSize.value = 50
    dataSource.value = data.value.slice(0, pageSize.value)
    return
  }
  dataSource.value = data.value.filter(item =>{
    return item.saleName.includes(IfNull(e))
  })
}
const resetBtn = () => {
  empName.value = null
  searchMsg.value = {
    month: transitionTimeYM(new Date().toLocaleDateString()),
  }
  pageSize.value = 50
  getSalesmanWorks()
}
const searchAdd = (val) => {
  empName.value = null
  let msg = cloneDeep(val)
  msg.month = transitionTimeYM(msg.month)
  searchMsg.value = msg
  pageSize.value = 50
  getSalesmanWorks()
}
const getSalesmanWorks = () => {
  loading.value = true
  salesmanWorks({
    ...searchMsg.value
  }).then( res => {
    if(res.code !== 10000) return
    data.value = res.data
    dataSource.value = data.value.slice(0, pageSize.value)
    let list = []
    res.data.forEach(item => {
      list.push({
        name:item.saleName,
        id:item.saleName
      })
    })
    empNameOpt.value = list
  }).finally(() => {
    loading.value = false
  })
}
const theBottom = () => {
  if (dataSource.value.length === 0) return
  if (pageSize.value > data.value.length) return
  pageSize.value += 50
  dataSource.value = data.value.slice(0, pageSize.value)
}
onMounted(() => {
  searchMsg.value = {
    month: transitionTimeYM(new Date().toLocaleDateString()),
  }
  getSalesmanWorks()
  getTableBody(tableRef.value.$el.querySelector('.ant-table-body'), theBottom)
})
const iptData = ref([
  {
    type: "selectTimeYM",
    placeholder: "年月",
    value: moment(new Date()),
    prop: "month",
    allowClear:false,
    width: 200,
  },
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    align: 'center',
    slots: {
      customRender: 'sort'
    },
    width: 70
  },
  {
    title: '业务员',
    dataIndex: 'saleName',
    align: 'center',
  },
  {
    title: '私海新增',
    children: [
      {
        title: '本月',
        dataIndex: 'privateCount.monthNum',
        align: 'center',
      },
      {
        title: '本周',
        dataIndex: 'privateCount.weekNum',
        align: 'center',
      },
      {
        title: '今日',
        dataIndex: 'privateCount.todayNum',
        align: 'center',
      },
      {
        title: '昨日',
        dataIndex: 'privateCount.yesterdayNum',
        align: 'center',
      },
    ],
    align: 'center',
  },
  {
    title: '私海跟进',
    children: [
      {
        title: '本月',
        dataIndex: 'privateFollowCount.monthNum',
        align: 'center',
      },
      {
        title: '本周',
        dataIndex: 'privateFollowCount.weekNum',
        align: 'center',
      },
      {
        title: '今日',
        dataIndex: 'privateFollowCount.todayNum',
        align: 'center',
      },
      {
        title: '昨日',
        dataIndex: 'privateFollowCount.yesterdayNum',
        align: 'center',
      },
    ],
    align: 'center',
  },
  {
    title: '合作新增',
    children: [
      {
        title: '本月',
        dataIndex: 'coopCount.monthNum',
        align: 'center',
      },
      {
        title: '本周',
        dataIndex: 'coopCount.weekNum',
        align: 'center',
      },
      {
        title: '今日',
        dataIndex: 'coopCount.todayNum',
        align: 'center',
      },
      {
        title: '昨日',
        dataIndex: 'coopCount.yesterdayNum',
        align: 'center',
      },
    ],
    align: 'center',
  },
  {
    title: '合作跟进',
    children: [
      {
        title: '本月',
        dataIndex: 'coopFollowCount.monthNum',
        align: 'center',
      },
      {
        title: '本周',
        dataIndex: 'coopFollowCount.weekNum',
        align: 'center',
      },
      {
        title: '今日',
        dataIndex: 'coopFollowCount.todayNum',
        align: 'center',
      },
      {
        title: '昨日',
        dataIndex: 'coopFollowCount.yesterdayNum',
        align: 'center',
      },
    ],
    align: 'center',
  },
  {
    title: '订单新增',
    children: [
      {
        title: '本月',
        dataIndex: 'orderCount.monthNum',
        align: 'center',
      },
      {
        title: '本周',
        dataIndex: 'orderCount.weekNum',
        align: 'center',
      },
      {
        title: '今日',
        dataIndex: 'orderCount.todayNum',
        align: 'center',
      },
      {
        title: '昨日',
        dataIndex: 'orderCount.yesterdayNum',
        align: 'center',
      },
    ],
    align: 'center',
  },
  {
    title: '车辆新增',
    children: [
      {
        title: '本月',
        dataIndex: 'orderVehicleCount.monthNum',
        align: 'center',
      },
      {
        title: '本周',
        dataIndex: 'orderVehicleCount.weekNum',
        align: 'center',
      },
      {
        title: '今日',
        dataIndex: 'orderVehicleCount.todayNum',
        align: 'center',
      },
      {
        title: '昨日',
        dataIndex: 'orderVehicleCount.yesterdayNum',
        align: 'center',
      },
    ],
    align: 'center',
  },
 
])
</script>

<style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>